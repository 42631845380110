import { Button, Pagination, TextField } from "@mui/material";
import { useState } from "react";

const PaginationComponent = ({ pageNumber, totalPages, handlePageChange }) => {
    const [jumpToPage, setJumpToPage] = useState(null);

    const handleJumpToPageInput = (e) => {
        setJumpToPage(e.target.value);
    }

    // when enter is pressed trigger Go To Page in pagination
    const handleOnKeyDownForJumpToPage = (e) => {
        if (e.keyCode == 13) {
            handleGoToPage();
        }
    }

    const handleGoToPage = () => {
        if (jumpToPage && jumpToPage > 0 && jumpToPage <= totalPages) {
            handlePageChange(null, jumpToPage);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
                placeholder='jump to'
                type="number"
                size="small"
                value={jumpToPage}
                onChange={handleJumpToPageInput}
                onKeyDown={handleOnKeyDownForJumpToPage}
                error={jumpToPage != null && (jumpToPage < 1 || jumpToPage > totalPages)}
                style={{ width: '90px' }}
            />

            <Button
                variant='contained'
                style={{ marginLeft: '12px', marginRight: '16px' }}
                sx={{ backgroundColor: '#dedede', color: '#777', minWidth: '0px', paddingLeft: '12px', paddingRight: '12px' }}
                onClick={handleGoToPage}>
                Go
            </Button>

            <Pagination
                page={pageNumber}
                onChange={handlePageChange}
                color="primary"
                count={totalPages}
                size="small"
            />
        </div>
    )

}

export default PaginationComponent;