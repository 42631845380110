import React, { useState } from 'react';
import ReactModal from 'react-modal';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import CandidateProfile from '../models/CandidateProfile';
import { useDispatch, useSelector, } from 'react-redux';
import { getSingleCandidateData } from '../redux/actions/candidateActions';
import CloseIcon from '@mui/icons-material/Close';
import { getGigApplicantsList } from '../redux/actions/gigsActions';
import { convertToShortYearAndMonth } from '../helpers/utility';
import PaginationComponent from '../components/PaginationComponent';

const GigApplicantsModal = ({ isOpen, onClose, gigApplicants, gigPostId }) => {

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0,
  });

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    },
  }
  const dispatch = useDispatch();
  const currentApplicants = gigApplicants?.data || []
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);

  const totalRecords = gigApplicants?.metadata?.totalRecords;
  const totalPages = gigApplicants?.metadata?.totalPages;

  const handlePageChange = (event, page) => {
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
    dispatch(getGigApplicantsList(gigPostId, page - 1));
  };

  const candidateById = useSelector((state) => state.candidate.candidateById);
  const candidateDataById = candidateById?.data;


  if (!gigApplicants) {
    return null; // Don't render anything if gigApplicants is null or undefined
  }

  const handleCloseCandidateModal = () => {
    setIsCandidateModalOpen(false);
  };

  const openCandidateDetailsModal = (applicant) => {
    dispatch(getSingleCandidateData(applicant.candidateId));
    setIsCandidateModalOpen(true)
  }


  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Gig Applicants Modal"
      style={customStyles}>
      <div style={{ width: '100%' }}>
        {/* Applicant Information */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Gig Applicants {totalRecords ? `(${totalRecords})` : ""}</h2>
          <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Candidate Name</TableCell>
                <TableCell style={styles.textBold}>Experience</TableCell>
                <TableCell style={styles.textBold}>Location</TableCell>
                <TableCell style={styles.textBold}>Job Location</TableCell>
                <TableCell style={styles.textBold}>Last Job Title</TableCell>
                <TableCell style={styles.textBold}>Last Job Company</TableCell>
                <TableCell style={styles.textBold}>Highest Education</TableCell>
                <TableCell style={styles.textBold}>Last Course Name</TableCell>
                <TableCell style={styles.textBold}>Applied On Date</TableCell>
                <TableCell style={styles.textBold}>Application Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {currentApplicants?.map((applicant) => (
                <TableRow key={applicant.candidateId} style={{ cursor: 'pointer' }}
                  onClick={() => openCandidateDetailsModal(applicant)}>
                  <TableCell>{applicant.candidateName}</TableCell>
                  <TableCell>{convertToShortYearAndMonth(applicant.monthsOfExperience)}</TableCell>
                  <TableCell>{applicant.location}</TableCell>
                  <TableCell>{applicant.jobLocation}</TableCell>
                  <TableCell>{applicant.lastJobTitle || 'Not specified'}</TableCell>
                  <TableCell>{applicant.lastJobCompany || 'Not specified'}</TableCell>
                  <TableCell>{applicant.highestEducation}</TableCell>
                  <TableCell>{applicant.lastCourseName || 'Not specified'}</TableCell>
                  <TableCell>{moment(applicant.appliedOnDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{applicant.applicationStatus || 'Not specified'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <PaginationComponent
            pageNumber={queryParams.pageNumber + 1}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      {isCandidateModalOpen && candidateDataById !== null && (
        <CandidateProfile
          isOpen={isCandidateModalOpen}
          onClose={handleCloseCandidateModal}
          candidateData={candidateDataById}
        />
      )}
    </ReactModal>
  );
};

export default GigApplicantsModal;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}