// reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userReducer';
import loaderReducer from './loaderReducer';
import employerReducer from './employerReducer'
import candidateReducer from './candidateReducer';
import jobReducer from './jobReducer';
import gigsReducer from './gigsReducer';
import commonReducer from './commonReducer';

const rootReducer = combineReducers({
  user: userReducer,
  loader: loaderReducer,
  employer: employerReducer,
  candidate: candidateReducer,
  job: jobReducer,
  gig: gigsReducer,
  common: commonReducer
});

export default rootReducer;
