import apiService from './apiService';

export const getJobsService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/job-posts?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getJobsAuditService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/audit/job-posts?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getSpecificJobsAuditService = async (jobPostId) => {
  const url = `/admin/audit/job-posts/${jobPostId}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getJobApplicantsService = async (jobPostId, pageNumber = 0) => {
  const url = `admin/job-posts/${jobPostId}/applicants?pageNumber=${pageNumber}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getSuggestedCandidatesService = async (jobPostId) => {
  const url = `/admin/job-posts/${jobPostId}/suggested-candidates`;

  const response = await apiService.get(url);
  return response.data;
};

export const getJobPostByIdService = async (jobPostId) => {
  const url = `/admin/job-posts/${jobPostId}`;

  const response = await apiService.get(url);
  return response.data;
};

export const createJobService = async (jobDetails) => {
  const url = `admin/job-posts`;

  const response = await apiService.post(url, jobDetails);
  return response.data;
};

export const editJobService = async (jobPostId, jobPostDetails) => {
  const url = `/admin/job-posts/${jobPostId}`;

  const response = await apiService.patch(url, jobPostDetails);
  return response.data;
};

export const deleteJobService = async (jobPostId) => {
  const url = `/admin/job-posts/${jobPostId}`;

  const response = await apiService.delete(url);
  return response.data;
};

export const bulkUpdateJobService = async (payload) => {
  const url = `admin/bulk-action/job-posts`;

  const response = await apiService.patch(url, payload);
  return response.data;
};

export const jobListsService = async (payload) => {
  const queryString = new URLSearchParams(payload).toString();
  const url = `/admin/job-roles?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const patchJobRoleService = async (jobRoleId, updatedData) => {
  const url = `/admin/job-roles/${jobRoleId}`;
  const response = await apiService.patch(url, updatedData);
  return response.data;
}

export const deleteJobRoleService = async (jobRoleId) => {
  const encodedJobRoleName = encodeURIComponent(jobRoleId);
  const url = `/admin/job-roles/${encodedJobRoleName}`;
  const response = await apiService.delete(url);
  return response.data;
}

export const createJobRoleService = async (payload) => {
  const url = `/admin/job-roles`;
  const response = await apiService.post(url, payload);
  return response.data;
}

export const onlyJobRolesService = async () => {
  const url = `admin/job-roles-only`;
  const response = await apiService.get(url);
  return response.data;
}


export const exportJobPostListService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/job-posts/export?${queryString}`;
  try {
    const response = await apiService.get(url, { responseType: 'blob' });
    // Create a blob object from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // Create a URL for the blob object
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;

    let currentTimestamp = new Date().getTime();
    const fileName = `JobPost_List_${currentTimestamp}.xlsx`;

    link.download = fileName;
    // Trigger the download
    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
    return { success: true };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
