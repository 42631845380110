import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../redux/actions/userActions';
import ViewUser from '../pages/ViewUser';
import ViewReferrals from '../pages/ViewReferrals';
import ViewEmployers from '../pages/ViewEmployers';
import ViewCandidates from '../pages/ViewCandidates';
import ViewJobs from '../pages/ViewJobs';
import ViewGigs from '../pages/ViewGigs';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ViewPendingGigApplications from '../pages/ViewPendingGigApplications';
import { CardGiftcard, Notifications } from '@mui/icons-material';
import { Ballot } from '@mui/icons-material';
import { PendingActions } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { userDetails } from '../redux/selectors/userSelector'
import HeaderComponent from '../components/HeaderComponent';
import ViewJobRoles from '../pages/ViewJobRoles';
import versionName from '../helpers/versionName.json';
import ViewAllAudit from '../pages/ViewAllAudit';
import ViewNotifications from '../pages/ViewNotifications';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1, 0.5),
  height: '20px',
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidenav() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const user = useSelector(userDetails);

  useEffect(() => {
    // Extract the pathname from the location object
    const { pathname } = location;

    // Handle navigation based on the pathname
    if (pathname.startsWith('/app/users')) {
      setSelectedMenuItem('users');
    } else if (pathname.startsWith('/app/referrals')) {
      setSelectedMenuItem('referrals');
    } else if (pathname.startsWith('/app/activity-logs')) {
      setSelectedMenuItem('activityLogs');
    } else if (pathname.startsWith('/app/employers')) {
      setSelectedMenuItem('employers');
    } else if (pathname.startsWith('/app/candidates')) {
      setSelectedMenuItem('candidates');
    } else if (pathname.startsWith('/app/jobs')) {
      setSelectedMenuItem('jobs');
    } else if (pathname.startsWith('/app/gigs')) {
      setSelectedMenuItem('gigs');
    } else if (pathname.startsWith('/app/pending-gig-applications')) {
      setSelectedMenuItem('pendingGigApplications');
    } else if (pathname.startsWith('/app/job-roles')) {
      setSelectedMenuItem('jobRoles');
    } else if (pathname.startsWith('/app/notifications')) {
      setSelectedMenuItem('notifications');
    }
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(setToken(null));
    handleProfileMenuClose();
  };

  const handleViewUserClick = () => {
    setSelectedMenuItem('users');
    navigate('/app/users');
    handleProfileMenuClose();
  };

  const handleDrawerListItemClick = (key, urlPath) => {
    setSelectedMenuItem(key);
    navigate(urlPath);
  }

  const profileMenuItems = [
    { label: 'Users', onClick: handleViewUserClick },
    { label: 'Logout', onClick: handleLogout },
  ];

  const getVersionName = useMemo(
    () => versionName[process.env.NODE_ENV], []
  );

  const drawerListItems = [
    { key: "candidates", title: "Candidates", path: "/app/candidates", icon: <PersonIcon /> },
    { key: "jobs", title: "Jobs", path: "/app/jobs", icon: <WorkIcon /> },
    { key: "employers", title: "Employers", path: "/app/employers", icon: <BadgeIcon /> },
    { key: "gigs", title: "Gigs", path: "/app/gigs", icon: <AttachMoneyIcon /> },
    { key: "pendingGigApplications", title: "Pending Gig Applications", path: "/app/pending-gig-applications", icon: <PersonSearchIcon /> },
    { key: "jobRoles", title: "Job Roles", path: "/app/job-roles", icon: <Ballot /> },
    { key: "notifications", title: "Notifications", path: "/app/notifications", icon: <Notifications /> },
    { key: "referrals", title: "Referrals", path: "/app/referrals", icon: <CardGiftcard /> },
    { key: "activityLogs", title: "Activity Logs [Beta]", path: "/app/activity-logs", icon: <PendingActions /> }
  ]

  const renderDrawerListItem = (key, title, icon, path) => {
    return (
      <ListItem
        key={key}
        disablePadding
        onClick={() => handleDrawerListItemClick(key, path)}
        sx={{
          display: 'block',
          backgroundColor: selectedMenuItem === key ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Tooltip title={title} placement='right'>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              my: "4px"
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    );
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} >
        <Toolbar variant='dense'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={process.env.PUBLIC_URL + '/rozgaar_logo.png'}
              style={{ maxHeight: '3em', width: 'auto' }}
              alt=''
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <span style={{ marginRight: '12px' }}>[{getVersionName}]</span>
            <span>{user && (user.mobileNumber)} - {user && user.name}</span>
            <IconButton
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
            >
              {profileMenuItems.map((item) => (
                <MenuItem key={item.label} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <List>
          {drawerListItems.map((item) => (
            renderDrawerListItem(item.key, item.title, item.icon, item.path)
          ))}
        </List>

      </Drawer >

      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '46px' }}>
        {selectedMenuItem === 'users' && <ViewUser />}
        {selectedMenuItem === 'referrals' && <ViewReferrals />}
        {selectedMenuItem === 'activityLogs' && <ViewAllAudit />}
        {selectedMenuItem === 'employers' && <ViewEmployers />}
        {selectedMenuItem === 'candidates' && <ViewCandidates />}
        {selectedMenuItem === 'jobs' && <ViewJobs />}
        {selectedMenuItem === 'gigs' && <ViewGigs />}
        {selectedMenuItem === 'pendingGigApplications' && <ViewPendingGigApplications />}
        {selectedMenuItem === 'jobRoles' && <ViewJobRoles />}
        {selectedMenuItem === 'notifications' && <ViewNotifications />}
      </Box >
    </Box >
  );
}

