import apiService from './apiService';


export const getNotificationsService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/notification?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};


export const getNotificationUsersService = async (notificationId, params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/notification/${notificationId}/users?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getShareLink = async (payload) => {
  const url = `/admin/share-link`;

  const response = await apiService.post(url, payload);
  return response.data;
};
