import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import moment from 'moment';
import { Button, Chip, CircularProgress, Divider, IconButton, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDocument } from '../redux/actions/userActions';
import ConfirmationDialog from '../components/ConfirmationDialog';
import clipboardService from '../utils/clipboardService';
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import { formatDateWithTime, formatDDMMYYYYDate } from '../helpers/formattedDateTime';
import { getSpecificCandidatesAuditList, resetSpecificCandidatesAudit } from '../redux/actions/candidateActions';
import constants from '../helpers/constants.json';
import { convertToShortYearAndMonth, convertToYearAndMonth } from '../helpers/utility';
import { Add, EventNote, ExpandLess, ExpandMore } from '@mui/icons-material';
import AddRemarksModal from './AddRemarksModal';
import ShareLinkComponent from '../components/ShareLinkComponent';

const CandidateProfileModal = ({ isOpen, onClose, candidateData, shouldScrollToRemarks }) => {
  const dummyImageURL = 'https://via.placeholder.com/150';
  const dispatch = useDispatch();
  const [isCandidateDocumentDeleteModalOpen, setIsCandidateDocumentDeleteModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [isActivityLogsVisible, setIsActivityLogsVisible] = useState(false);
  const [isRemarksHistoryVisible, setIsRemarksHistoryVisible] = useState(false);
  const [isAddRemarksModalVisible, setIsAddRemarksModalVisible] = useState(false);
  const { revisionType, docType } = constants;

  const remarksRef = useRef(null);

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // maxWidth: '800px',
      width: '75%',
      maxHeight: '90%',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      padding: '20px',
      overflow: 'auto',
    },
  };

  const mainStyle = {
    divStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '4px'
    },
    spanStyle: {
      color: 'gray',
      textAlign: 'right',
      marginRight: '8px',
      minWidth: '130px',
      width: '130px',
      fontSize: '14px'
    }
  };

  const specificCandidatesAuditResponse = useSelector((state) => state.candidate.specificCandidatesAudit);
  const specificCandidatesAudit = specificCandidatesAuditResponse?.data;
  const auditLoader = useSelector((state) => state.loader.loading);

  const rmList = candidateData?.remarksList;
  // get most recent remark; assuming remarksList is sorted by create date in descending order
  const currentRemarkObj = (rmList && rmList?.length != 0) ? rmList[0] : null;

  useEffect(() => {
    return () => {
      dispatch(resetSpecificCandidatesAudit());
    };
  }, []);

  useEffect(() => {
    // check if we need to scroll to remarks automatically using prop
    // if yes then first show the remarks history and using useEffect hook scroll to remarks section
    if (shouldScrollToRemarks && candidateData?.remarksList?.length > 0) {
      setIsRemarksHistoryVisible(true);
    }
  }, [shouldScrollToRemarks, candidateData]);

  useEffect(() => {
    // scroll to remarks section automatically if prop is true
    if (shouldScrollToRemarks) {
      // check if remarks section is rendered
      // if yes then scroll to it
      // if not then delay scrolling by 500ms
      if (remarksRef?.current) {
        scrollToRemarks();
      } else {
        scrollToRemarksAfterTimeout();
      }
    }
  }, [isRemarksHistoryVisible]);

  const scrollToRemarksAfterTimeout = () => {
    setTimeout(() => {
      scrollToRemarks();
    }, 500);
  }

  const scrollToRemarks = () => {
    remarksRef?.current?.scrollIntoView({ behavior: "smooth" });
  }

  const resumeDoc = useMemo(
    () => candidateData?.documents.find((d) => d.type === docType.cvResume),
    [candidateData?.documents]
  );

  if (!candidateData) {
    return null; // Don't render anything if candidateData is null or undefined
  }

  const handleCopyClick = (shareLink) => {
    clipboardService.copyToClipboard(shareLink);
  };

  const deleteUserDocument = (id) => {
    setDocumentId(id);
    setIsCandidateDocumentDeleteModalOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setIsCandidateDocumentDeleteModalOpen(false);
  };

  const handleConfirmationDialog = () => {
    dispatch(deleteDocument("candidate", documentId));
    setIsCandidateDocumentDeleteModalOpen(false);
  };

  const handleExpandActivityLogs = () => {
    if (specificCandidatesAudit == null && !isActivityLogsVisible) {
      dispatch(getSpecificCandidatesAuditList(candidateData?.candidateId));
    }
    setIsActivityLogsVisible((prevState) => !prevState);
  }

  const handleExpandRemarksHistory = () => {
    setIsRemarksHistoryVisible((prevState) => !prevState);
  }

  const handleAddRemarks = () => {
    setIsAddRemarksModalVisible(true);
  }

  const handleCloseAddRemarksModal = () => {
    setIsAddRemarksModalVisible(false);
  }

  const preferredRoles = [
    candidateData.preferredRole1,
    candidateData.preferredRole2,
    candidateData.preferredRole3
  ].filter(Boolean);

  const viewResume = () => {
    window.open(resumeDoc.url, "_blank");
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Candidate Profile Modal"
      style={customStyles}
    >

      <style>
        {`
          .ProfileTables table, .ProfileTables th, .ProfileTables td{
            border:1px solid #ddd;
            border-collapse: collapse;
            padding: 6px;
          }
        `}
      </style>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h2>Candidate Profile</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div style={{ textAlign: 'left', color: '#333' }}>

        {/* Header (Profile Pic and Important Info) */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img
              src={candidateData?.profilePic || dummyImageURL}
              alt="Profile"
              style={{ width: '100px', height: '100px', borderRadius: '50%', border: '1px solid #333' }}
            />
          </div>
          <div style={{ marginLeft: '48px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <span style={{ color: 'gray', fontSize: '14px' }}>Mobile Number: </span>
                <span>{candidateData.mobileNumber}</span>
                <IconButton style={{ marginLeft: '4px' }} color='primary' onClick={() => handleCopyClick(candidateData.mobileNumber)}>
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </div>
              <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', columnGap: '8px', alignItems: 'center' }}>
                <div>
                  <ShareLinkComponent
                    entityId={candidateData.candidateId}
                    shareLinkType={constants.entityType.CANDIDATE}
                  />
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  {resumeDoc &&
                    <Tooltip title="View Resume">
                      <Button style={{ minWidth: '32px' }} color="primary" onClick={viewResume}>
                        <VisibilityIcon />
                      </Button>
                    </Tooltip>
                  }
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  <Tooltip title="View Remarks">
                    <Button style={{ minWidth: '32px' }} color="primary" onClick={scrollToRemarks}>
                      <EventNote />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Personal Information */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Personal Information</h3>
          <Grid container>
            <Grid size={{ xs: 6 }}>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Name:</span>
                <span>{candidateData.name} {candidateData.nonAppUser && <span>[Non App User]</span>}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Mobile Number:</span>
                <span>{candidateData.mobileNumber}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Location:</span>
                <span>{candidateData.location}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Job Location:</span>
                <span>{candidateData.jobLocation}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Age:</span>
                <span>{candidateData.dob && formatDDMMYYYYDate(candidateData.dob) + " (" + candidateData.age + " years)"}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>English Proficiency:</span>
                <span>{candidateData.englishProficiency}</span>
              </div>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Gender:</span>
                <span>{candidateData.gender}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Preferred Role:</span>
                <span>
                  <Stack direction="row" flexWrap={"wrap"}>
                    {preferredRoles.map((role, index) => (
                      <Chip key={index} label={role} style={{ marginBottom: '2px', fontSize: '14px' }} />
                    ))}
                  </Stack>
                </span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Current Remarks:</span>
                <span>{currentRemarkObj != null ? currentRemarkObj.remarks : 'No remarks'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Active:</span>
                <span>{candidateData.active ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Looking For Job:</span>
                <span>{candidateData.lookingForJob ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Profile Incomplete:</span>
                <span>{candidateData.profileInComplete ? 'Yes' : 'No'}</span>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Description */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Description:</h3>
          <div>{candidateData.description || 'No description provided'}</div>
        </div>

        {/* Experience */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Experience [{convertToYearAndMonth(candidateData.monthsOfExperience)}]:</h3>
          {candidateData.experience.length > 0 ? (
            <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th><strong>Company</strong></th>
                  <th><strong>Job Title</strong></th>
                  <th><strong>Experience</strong></th>
                  <th><strong>Salary</strong></th>
                  <th><strong>Start Date</strong></th>
                  <th><strong>End Date</strong></th>
                </tr>
              </thead>
              <tbody>
                {candidateData.experience.map((exp) => (
                  <tr key={exp.id}>
                    <td>{exp.companyName}</td>
                    <td>{exp.jobTitle}</td>
                    <td>{convertToShortYearAndMonth(exp.monthsOfExperience)}</td>
                    <td>{formatIndianCurrency(exp.salary)}</td>
                    <td>{exp.startDate && moment(exp.startDate).format('DD/MM/YYYY')}</td>
                    <td>{exp.endDate && moment(exp.endDate).format('DD/MM/YYYY')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No experience</div>
          )}
        </div>

        {/* Education */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Education:</h3>
          <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
            <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
              <tr>
                <th><strong>Highest Education</strong></th>
                <th><strong>Institute Name</strong></th>
                <th><strong>Course Name</strong></th>
                <th><strong>Start Year</strong></th>
                <th><strong>End Year</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{candidateData.highestEducation || 'Not specified'}</td>
                <td>{candidateData.education.institutionName || 'Not specified'}</td>
                <td>{candidateData.education.courseName || 'Not specified'}</td>
                <td>{candidateData.education.startYear || 'Not specified'}</td>
                <td>{candidateData.education.endYear || 'Not specified'}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Documents */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Documents:</h3>
          {candidateData.documents.length > 0 ? (
            <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th><strong>Document Name</strong></th>
                  <th><strong>View Document</strong></th>
                  <th><strong>Create Date</strong></th>
                  <th><strong>Update Date</strong></th>
                  <th><strong>Delete Document</strong></th>
                </tr>
              </thead>
              <tbody>
                {candidateData.documents.map((doc) => (
                  <tr key={doc.id}>
                    <td>{doc.type}</td>
                    <td>
                      <a href={doc.url} target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td>{doc.createDate ? moment(doc.createDate).format("DD/MM/YYYY") : '-'}</td>
                    <td>{doc.updateDate ? moment(doc.updateDate).format("DD/MM/YYYY") : '-'}</td>
                    <td>
                      <IconButton onClick={() => deleteUserDocument(doc.id)}>
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No Documents</div>
          )}
        </div>

        {/* Profile Create and Update Dates */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Profile Creation and Updating:</h3>
          <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
            <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
              <tr>
                <th><strong>Create Date</strong></th>
                <th><strong>Update Date</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{candidateData.createDate ? formatDDMMYYYYDate(candidateData.createDate) : "-"}</td>
                <td>{candidateData.updateDate ? formatDDMMYYYYDate(candidateData.updateDate) : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Remarks */}
        <div ref={remarksRef} style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Remarks History:
            <Button
              style={{ marginLeft: '8px' }}
              size='small'
              variant='outlined'
              disabled={candidateData?.remarksList?.length == 0}
              endIcon={(isRemarksHistoryVisible) ? <ExpandLess /> : <ExpandMore />}
              onClick={handleExpandRemarksHistory}>
              {(isRemarksHistoryVisible) ? "Hide" : "View"}
            </Button>

            <Button
              style={{ marginLeft: '32px' }}
              size='small'
              variant='outlined'
              endIcon={<Add />}
              onClick={handleAddRemarks}
            >
              Add Remarks
            </Button>
          </h3>
          {isRemarksHistoryVisible && candidateData?.remarksList?.length > 0 && (
            <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th><strong>Remarks</strong></th>
                  <th><strong>Added By</strong></th>
                  <th><strong>Create Date</strong></th>
                  <th><strong>Update Date</strong></th>
                </tr>
              </thead>
              <tbody>
                {candidateData.remarksList.map((rm) => (
                  <tr key={rm.id}>
                    <td>{rm.remarks}</td>
                    <td>{rm.adminUserName}</td>
                    <td>{rm.createDate && moment(rm.createDate).format('DD/MM/YYYY')}</td>
                    <td>{rm.updateDate && moment(rm.updateDate).format('DD/MM/YYYY')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {candidateData?.remarksList?.length == 0 && <div>No Remarks</div>}

        </div>

        {/* Activity Logs */}
        <div style={{ marginTop: '12px' }}>

          <h3 style={{ margin: 0, display: 'flex' }}>
            Activity Logs:
            <Button
              style={{ marginLeft: '8px' }}
              size='small'
              variant='outlined'
              disabled={auditLoader}
              endIcon={(isActivityLogsVisible && specificCandidatesAudit) ? <ExpandLess /> : <ExpandMore />}
              onClick={handleExpandActivityLogs}>
              {(isActivityLogsVisible && specificCandidatesAudit) ? "Hide" : "View"}
            </Button>
            {auditLoader && !specificCandidatesAudit &&
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}><CircularProgress size={24} /></div>
            }
          </h3>

          {isActivityLogsVisible && specificCandidatesAudit && specificCandidatesAudit.length > 0 &&
            <table className='ProfileTables' style={{ tableLayout: 'fixed', wordBreak: 'break-word', width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#d2d2d2', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th>Property Name</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {specificCandidatesAudit.map((item, index) => (
                  <>
                    <tr key={index} style={{ backgroundColor: '#f2f2f2' }}>
                      <td colSpan={3}>
                        <div style={{ flexDirection: 'row', display: 'flex', fontWeight: 'bold' }}>
                          <div style={{ fontSize: '16px', display: 'inline-block', marginRight: '8px' }}>{item.updatedBy}</div>
                          <div style={{ marginRight: '8px' }}>{revisionType[item.revisionType]}</div>
                          <div style={{ marginRight: '8px' }}>on</div>
                          <div style={{ display: 'inline-block' }}>{item.revisionDate && formatDateWithTime(item.revisionDate)}</div>
                        </div>
                      </td>
                    </tr>

                    {item.properties.length != 0 && item.properties.map((prop) => (
                      <>
                        <tr>
                          <td><div>{prop.propertyName}</div></td>
                          <td><div>{prop.oldValue}</div></td>
                          <td><div>{prop.newValue}</div></td>
                        </tr>
                      </>
                    ))}

                  </>
                ))}
              </tbody>
            </table>
          }

        </div>

      </div>

      {/* Delete Confirmation Dialog */}
      {isCandidateDocumentDeleteModalOpen && (
        <ConfirmationDialog
          open={true}
          onClose={handleConfirmationDialogClose}
          onConfirm={handleConfirmationDialog}
          message="Do you want to Delete?"
        />
      )}

      {isAddRemarksModalVisible &&
        <AddRemarksModal
          open={true}
          onClose={handleCloseAddRemarksModal}
          isCandidate={true}
          userId={candidateData?.candidateId}
        />
      }


    </ReactModal>
  );
};

export default CandidateProfileModal;
