import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import { getSingleCandidateData } from '../redux/actions/candidateActions';
import { getSingleEmployerData } from '../redux/actions/employerActions';
import { editCandidateService } from '../redux/service/candidateService';
import { editEmployerService } from '../redux/service/employerService';


const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(4px)',
        zIndex: 1000,
        marginTop: '2em'
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        borderRadius: '8px',
        maxWidth: '40%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
};

const AddRemarksModal = ({ open, onClose, userId, isCandidate }) => {
    const dispatch = useDispatch();
    const [remarkText, setRemarkText] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const handleSaveRemarks = () => {
        if (remarkText) {
            let bodyJson = {
                'remarks': remarkText
            };
            setIsSaving(true);
            if (isCandidate) {
                editCandidateService(userId, bodyJson).then(async (data) => {
                    await dispatch(getSingleCandidateData(userId));
                    setIsSaving(false);
                    onClose();
                }).catch((err) => {
                    setIsSaving(false);
                });
            } else {
                editEmployerService(userId, bodyJson).then(async (data) => {
                    await dispatch(getSingleEmployerData(userId));
                    setIsSaving(false);
                    onClose();
                }).catch((err) => {
                    setIsSaving(false);
                });
            }
        }
    }

    const isNullOrEmpty = (str) => {
        return !(str && str.trim());
    }

    return (
        <>
            <ReactModal
                isOpen={open}
                onRequestClose={onClose}
                contentLabel="Add Remarks Modal"
                style={customModalStyles}
            >
                <div>

                    <div style={{ fontSize: '18px', fontWeight: 'bold' }}>Add New Remark:</div>
                    <TextField
                        placeholder='Remarks'
                        type="text"
                        size="small"
                        rows={4}
                        multiline={true}
                        style={{ marginTop: '16px', width: '100%' }}
                        value={remarkText}
                        onChange={(e) => setRemarkText(e.target.value)}
                        autoFocus={true}
                    />

                    <div style={{ marginTop: '24px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button
                            style={{ width: '120px' }}
                            size='small'
                            variant='outlined'
                            onClick={onClose}
                        >Cancel</Button>
                        <Button
                            style={{ marginLeft: '12px', width: '120px' }}
                            size='small'
                            variant='contained'
                            onClick={handleSaveRemarks}
                            disabled={isNullOrEmpty(remarkText)}
                        >{isSaving ? 'Saving' : 'Save'}</Button>

                    </div>

                </div>
            </ReactModal>
        </>
    );
};

export default AddRemarksModal;
