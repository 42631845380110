import apiService from './apiService';

export const getEmployerService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/employers?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getEmployersAuditService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/audit/employers?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getSpecificEmployersAuditService = async (employerId) => {
  const url = `/admin/audit/employers/${employerId}`;

  const response = await apiService.get(url);
  return response.data;
};

// TODO: as a temporary workaround used employer specific state for all types of audit details
export const getSpecificEmployersAuditRevisionService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/audit/revision?${queryString}`;

  const response = await apiService.get(url);
  return response.data;
};

export const getSingleEmployerService = async (employerId) => {
  const url = `/admin/employers/${employerId}`;

  const response = await apiService.get(url);
  return response.data;
};

export const addEmployerService = async (employer) => {
  const url = `/admin/employers`;

  const response = await apiService.post(url, employer);
  return response.data;
};

export const editEmployerService = async (employerId, employer) => {
  const url = `/admin/employers/${employerId}`;

  const response = await apiService.patch(url, employer);
  return response.data;
};

export const deleteEmployerService = async (employerId) => {
  const url = `/admin/employers/${employerId}`;
  const response = await apiService.delete(url);
  return response.data;
};

export const exportEmployerListService = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/admin/employers/export?${queryString}`;

  try {
    const response = await apiService.get(url, { responseType: 'blob' });
    // Create a blob object from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // Create a URL for the blob object
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;

    let currentTimestamp = new Date().getTime();
    const fileName = `Employer_List_${currentTimestamp}.xlsx`;

    link.download = fileName;
    // Trigger the download
    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
    return { success: true };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
