// CandidateFilters.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import constants from '../helpers/constants.json';
import { Autocomplete, Button, Chip, Divider, TextField, Tooltip } from '@mui/material';
import DatePicker from 'react-datepicker';
import { onlyJobLocationsList, setCandidateFilters } from '../redux/actions/candidateActions';
import Select from "react-select";
import { formatYYYYMMDDDate } from '../helpers/formattedDateTime';
import { ArrowDownward, ArrowUpward, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { onlyJobRolesList } from '../redux/actions/jobActions';

const CandidateFilters = ({ onClose, filtersData }) => {
    const dispatch = useDispatch();

    const onlyJobRoles = useSelector((state) => state.job.onlyJobRoles);
    const onlyJobLocations = useSelector((state) => state.candidate.onlyJobLocations);
    const candidateFilters = useSelector((state) => state.candidate.candidateFilters);

    const [sortDirection, setSortDirection] = useState('desc');

    const initSortOrder = filtersData.sort !== '' ? constants.candidateSortOrders?.find(option => option.id === (filtersData.sort?.split(":")[0])) : '';
    const [sortOrderFilter, setSortOrderFilter] = useState(initSortOrder);

    const initGender = filtersData.gender !== '' ? constants.gender?.find(option => option.id === filtersData.gender) : '';
    const [genderFilter, setGenderFilter] = useState(initGender);

    const initHighestEducation = filtersData.highestEducation !== '' ? constants.educationList?.find(option => option.id === filtersData.highestEducation) : '';
    const [highestEducationFilter, setHighestEducationFilter] = useState(initHighestEducation);

    const initExpLevel = filtersData.experienceLevel !== '' ? constants.experienceLevel?.find(option => option.id === filtersData.experienceLevel) : '';
    const [expLevelFilter, setExpLevelFilter] = useState(initExpLevel);

    const initWorkExp = filtersData.workExperience !== '' ? filtersData.workExperience?.split("|") : [];
    const [workExpFilter, setWorkExpFilter] = useState(initWorkExp);

    const initPreferredRole = filtersData.hasOwnProperty('preferredRole') && filtersData.preferredRole !== '' ? filtersData.preferredRole.split("|").map(i => ({ 'id': i, 'name': i })) : [];
    const [preferredRoleFilter, setPreferredRoleFilter] = useState(initPreferredRole);

    const initJobLocation = filtersData.jobLocation !== '' ? filtersData.jobLocation?.split("|") : [];
    const [jobLocationFilter, setJobLocationFilter] = useState(initJobLocation);

    const initUserType = filtersData.userType !== '' ? constants.userType?.find(option => option.id === filtersData.userType) : '';
    const [userTypeFilter, setUserTypeFilter] = useState(initUserType);

    const initActive = filtersData.active !== '' ? constants.accountDeleteRequestBool?.find(option => option.id === filtersData.active) : '';
    const [activeFilter, setActiveFilter] = useState(initActive);

    const initLookingForJob = filtersData.lookingForJob !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.lookingForJob) : '';
    const [lookingForJobFilter, setLookingForJobFilter] = useState(initLookingForJob);

    const initProfileIncomplete = filtersData.profileIncomplete !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.profileIncomplete) : '';
    const [profileIncompleteFilter, setProfileIncompleteFilter] = useState(initProfileIncomplete);

    const [lowerSalaryFilter, setLowerSalaryFilter] = useState(filtersData.salaryLowerRange);
    const [upperSalaryFilter, setUpperSalaryFilter] = useState(filtersData.salaryUpperRange);

    const [lowerSalaryInput, setLowerSalaryInput] = useState(filtersData.salaryLowerRange);
    const [upperSalaryInput, setUpperSalaryInput] = useState(filtersData.salaryUpperRange);

    const initFromCreateDate = filtersData.hasOwnProperty("fromCreateDate") && filtersData.fromCreateDate !== '' ? new Date(filtersData.fromCreateDate) : '';
    const [fromCreateDateFilter, setFromCreateDateFilter] = useState(initFromCreateDate);

    const initToCreateDate = filtersData.hasOwnProperty("toCreateDate") && filtersData.toCreateDate !== '' ? new Date(filtersData.toCreateDate) : '';
    const [toCreateDateFilter, setToCreateDateFilter] = useState(initToCreateDate);

    const initUpdateDate = filtersData.hasOwnProperty("updateDate") && filtersData.updateDate !== '' ? new Date(filtersData.updateDate) : '';
    const [updateDateFilter, setUpdateDateFilter] = useState(initUpdateDate);

    useEffect(() => {
        dispatch(onlyJobRolesList());
        dispatch(onlyJobLocationsList());
    }, [dispatch]);

    const handleResetAll = () => {
        let canFilters = {
            sort: '',
            gender: '',
            highestEducation: '',
            experienceLevel: '',
            workExperience: '',
            preferredRole: '',
            jobLocation: '',
            userType: '',
            active: '',
            lookingForJob: '',
            profileIncomplete: '',
            salaryLowerRange: '',
            salaryUpperRange: '',
            fromCreateDate: '',
            toCreateDate: '',
            updateDate: ''
        };

        canFilters['isApplied'] = false;

        dispatch(setCandidateFilters(canFilters));

        setSortOrderFilter('');
        setGenderFilter('');
        setHighestEducationFilter('');
        setExpLevelFilter('');
        setWorkExpFilter([]);
        setPreferredRoleFilter([]);
        setJobLocationFilter([]);
        setUserTypeFilter('');
        setActiveFilter('')
        setLookingForJobFilter('');
        setProfileIncompleteFilter('');
        setLowerSalaryFilter('');
        setLowerSalaryInput('');
        setUpperSalaryFilter('');
        setUpperSalaryInput('');
        setFromCreateDateFilter('');
        setToCreateDateFilter('');
        setUpdateDateFilter('');

    }

    const handleFilterChange = (filterType, e) => {
        let canFilters = { ...candidateFilters };
        let filterValue = e?.id;
        canFilters[filterType] = filterValue;

        switch (filterType) {
            case 'sort':
                let asortOrder = filterValue + ":" + sortDirection;
                canFilters[filterType] = asortOrder;
                setSortOrderFilter(e);
                break;
            case 'gender':
                setGenderFilter(e);
                break;
            case 'highestEducation':
                setHighestEducationFilter(e);
                break;
            case 'experienceLevel':
                setExpLevelFilter(e);
                break;
            case 'workExperience':
                canFilters[filterType] = e.join("|");
                setWorkExpFilter(e);
                break;
            case 'preferredRole':
                canFilters[filterType] = e.map(i => i.id).join("|");
                setPreferredRoleFilter(e);
                break;
            case 'jobLocation':
                canFilters[filterType] = e.join("|");
                setJobLocationFilter(e);
                break;
            case 'userType':
                setUserTypeFilter(e);
                break;
            case 'active':
                setActiveFilter(e);
                break;
            case 'lookingForJob':
                setLookingForJobFilter(e);
                break;
            case 'profileIncomplete':
                setProfileIncompleteFilter(e);
                break;
            case 'salaryLowerRange':
                canFilters[filterType] = e;
                setLowerSalaryFilter(e);
                break;
            case 'salaryUpperRange':
                canFilters[filterType] = e;
                setUpperSalaryFilter(e);
                break;
            case 'fromCreateDate':
                filterValue = e;
                canFilters[filterType] = formatYYYYMMDDDate(filterValue);
                setFromCreateDateFilter(filterValue);
                break;
            case 'toCreateDate':
                filterValue = e;
                canFilters[filterType] = formatYYYYMMDDDate(filterValue);
                setToCreateDateFilter(filterValue);
                break;
            case 'updateDate':
                canFilters[filterType] = formatYYYYMMDDDate(e);
                setUpdateDateFilter(e);
                break;
            default:
                break;
        }

        canFilters['isApplied'] = checkIfAnyFilterIsApplied(canFilters);

        dispatch(setCandidateFilters(canFilters));
    }

    const isBoolean = (str) => {
        return (typeof str == 'boolean');
    }

    const checkIfAnyFilterIsApplied = (filters) => {
        return (filters.sort || filters.gender || filters.highestEducation || filters.experienceLevel ||
            filters.workExperience || filters.preferredRole || filters.jobLocation ||
            isBoolean(filters.userType) || isBoolean(filters.active) || isBoolean(filters.lookingForJob) ||
            isBoolean(filters.profileIncomplete) || filters.fromCreateDate || filters.toCreateDate ||
            filters.updateDate || filters.salaryLowerRange || filters.salaryUpperRange) ? true : false;
    }

    const handleResetSpecific = (filterType) => {
        let canFilters = { ...candidateFilters };

        canFilters[filterType] = '';
        switch (filterType) {
            case 'sort':
                setSortOrderFilter('');
                break;
            case 'gender':
                setGenderFilter('');
                break;
            case 'highestEducation':
                setHighestEducationFilter('');
                break;
            case 'experienceLevel':
                setExpLevelFilter('');
                break;
            case 'workExperience':
                setWorkExpFilter([]);
                break;
            case 'preferredRole':
                setPreferredRoleFilter([]);
                break;
            case 'jobLocation':
                setJobLocationFilter([]);
                break;
            case 'userType':
                setUserTypeFilter('');
                break;
            case 'active':
                setActiveFilter('');
                break;
            case 'lookingForJob':
                setLookingForJobFilter('');
                break;
            case 'profileIncomplete':
                setProfileIncompleteFilter('');
                break;
            case 'updateDate':
                setUpdateDateFilter('');
                break;
            default:
                break;
        }

        canFilters['isApplied'] = checkIfAnyFilterIsApplied(canFilters);

        dispatch(setCandidateFilters(canFilters));
    }

    const handleResetSalaryRangeFilter = () => {
        let canFilters = { ...candidateFilters };
        canFilters['salaryLowerRange'] = '';
        canFilters['salaryUpperRange'] = '';
        setLowerSalaryInput('');
        setLowerSalaryFilter('');
        setUpperSalaryInput('');
        setUpperSalaryFilter('');

        canFilters['isApplied'] = checkIfAnyFilterIsApplied(canFilters);

        dispatch(setCandidateFilters(canFilters));
    }

    const handleResetCreateDateRangeFilter = () => {
        let canFilters = { ...candidateFilters };
        canFilters['fromCreateDate'] = '';
        canFilters['toCreateDate'] = '';
        setFromCreateDateFilter('');
        setToCreateDateFilter('');

        canFilters['isApplied'] = checkIfAnyFilterIsApplied(canFilters);

        dispatch(setCandidateFilters(canFilters));
    }

    const changeSortDirection = () => {
        let aDirection = 'asc';
        if (sortDirection == 'asc') {
            aDirection = 'desc';
        }

        let asortOrder = sortOrderFilter?.id + ":" + aDirection;
        let canFilters = { ...candidateFilters };
        canFilters['sort'] = asortOrder;

        setSortDirection(aDirection);
        dispatch(setCandidateFilters(canFilters));
    }

    const shouldDisableResetButton = (filterType, filterState) => {
        let shouldDisable = true;
        switch (filterType) {
            case 'sort':
            case 'gender':
            case 'highestEducation':
            case 'experienceLevel':
            case 'userType':
            case 'active':
            case 'lookingForJob':
            case 'profileIncomplete':
                shouldDisable = !filterState?.hasOwnProperty('id');
                break;
            case 'workExperience':
            case 'preferredRole':
            case 'jobLocation':
                shouldDisable = (filterState.length == 0);
                break;
            case 'updateDate':
                shouldDisable = !Boolean(filterState);
                break;
            default:
                break;
        }
        return shouldDisable;
    }

    const handleOnKeyDownSalaryRange = (e, filterType) => {
        if (e.keyCode == 13) {
            let inputValue = e.target.value;
            handleFilterChange(filterType, inputValue);
        }
    }

    const FilterHeader = (label, filterType, filterState) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className='canFilterLabel'>{label}</div>
                <Button sx={{ textTransform: 'none' }} size='small' variant="text"
                    disabled={shouldDisableResetButton(filterType, filterState)}
                    onClick={() => handleResetSpecific(filterType)}>
                    Reset
                </Button>
            </div>
        )
    }

    const getReadableSortDirection = () => {
        let readableDirection = (sortDirection == 'asc') ? '(Ascending)' : '(Descending)';
        return readableDirection;
    }

    const customSelectStyle = (filterState) => ({
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: filterState?.hasOwnProperty('id') ? 'green' : '#ccc',
            boxShadow: filterState?.hasOwnProperty('id')
                ? '0 0 0 1px green'
                : (state.isFocused ? '0 0 0 1px #2684FF' : 'none')
        }),
        // singleValue: (baseStyles) => ({
        //     ...baseStyles,
        //     color: 'green'
        // })
    })

    const customMultiSelectStyle = (filterState) => ({
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: filterState?.length != 0 ? 'green' : '#ccc',
            boxShadow: filterState?.length != 0
                ? '0 0 0 1px green'
                : (state.isFocused ? '0 0 0 1px #2684FF' : 'none')
        })
    })

    const customAutocompleteStyle = (filterState) => ({
        '& .MuiOutlinedInput-root': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: filterState?.length != 0 && 'green',
                borderWidth: filterState?.length != 0 && '2px'
            }
        }
    })

    const customInputStyle = (filterState) => ({
        '& .MuiOutlinedInput-root': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: filterState && 'green',
                borderWidth: filterState && '2px'
            }
        }
    })

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton style={{ marginRight: '8px' }} color="primary" onClick={onClose}>
                    <KeyboardDoubleArrowRight />
                </IconButton>
                <Divider orientation='vertical' flexItem variant='middle' />
                <div style={{ flex: 1, marginLeft: '8px', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Filters:</div>
                <Divider orientation='vertical' flexItem variant='middle' />
                <Button style={{ marginLeft: '8px' }} variant="text" onClick={handleResetAll}>
                    Clear All
                </Button>
            </div>

            <Divider style={{ marginBottom: '16px', marginLeft: '-16px' }} />

            <style>{`
                    .filterFullWidthDatePicker > .react-datepicker-wrapper{
                        width: 100%
                    }
                    .canFilterLabel {
                        font-size: 14px;
                    }
                `}</style>

            <div>

                {/* Sort By Section */}
                <div>
                    {FilterHeader('Sort by: ' + getReadableSortDirection(), 'sort', sortOrderFilter)}
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <Select
                                defaultValue={sortOrderFilter}
                                value={sortOrderFilter}
                                placeholder="..."
                                options={constants.candidateSortOrders}
                                onChange={(e) => handleFilterChange("sort", e)}
                                getOptionLabel={(option) => option?.name.toString() || ""}
                                getOptionValue={(option) => option?.id.toString() || ""}
                                menuPortalTarget={document.body}
                                styles={customSelectStyle(sortOrderFilter)}
                            />
                        </div>
                        {/* Sort By Direction Icon Button */}
                        <Tooltip title={sortDirection == 'asc' ? 'Descending' : 'Ascending'}>
                            <Button
                                variant='outlined'
                                size='small'
                                style={{ marginLeft: '8px', minWidth: '32px' }}
                                onClick={changeSortDirection}
                                disabled={sortOrderFilter == '' || sortOrderFilter == null}
                            >
                                {sortDirection == 'asc' ?
                                    <ArrowDownward />
                                    :
                                    <ArrowUpward />
                                }
                            </Button>
                        </Tooltip>
                    </div>
                </div>

                {/* Gender Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Gender:', 'gender', genderFilter)}

                    <Select
                        defaultValue={genderFilter}
                        value={genderFilter}
                        placeholder="..."
                        options={constants.gender}
                        onChange={(e) => handleFilterChange("gender", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(genderFilter)}
                    />
                </div>

                {/* Education Qualification Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Education Qualification:', 'highestEducation', highestEducationFilter)}
                    <Select
                        defaultValue={highestEducationFilter}
                        value={highestEducationFilter}
                        placeholder="..."
                        options={constants.educationList}
                        onChange={(e) => handleFilterChange("highestEducation", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(highestEducationFilter)}
                    />
                </div>

                {/* Experience Level Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Experience Level:', 'experienceLevel', expLevelFilter)}
                    <Select
                        defaultValue={expLevelFilter}
                        value={expLevelFilter}
                        placeholder="..."
                        options={constants.experienceLevel}
                        onChange={(e) => handleFilterChange("experienceLevel", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(expLevelFilter)}
                    />
                </div>

                {/* Work Experiences Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Work Experiences:', 'workExperience', workExpFilter)}
                    <Autocomplete
                        multiple
                        limitTags={3}
                        id="work-exp-filter"
                        options={[]}
                        defaultValue={workExpFilter}
                        value={workExpFilter}
                        size='small'
                        freeSolo={true}
                        onChange={(e, newValue) => handleFilterChange("workExperience", newValue)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return (
                                    <Chip variant="filled" label={option} key={key} {...tagProps} />
                                );
                            })
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                // label="Work Experiences:"
                                placeholder="..."
                            />
                        )}
                        sx={customAutocompleteStyle(workExpFilter)}
                    />
                </div>

                {/* Preferred Role Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Preferred Role:', 'preferredRole', preferredRoleFilter)}
                    <Select
                        defaultValue={preferredRoleFilter}
                        value={preferredRoleFilter}
                        placeholder="..."
                        isMulti={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={onlyJobRoles}
                        onChange={(e) => handleFilterChange("preferredRole", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customMultiSelectStyle(preferredRoleFilter)}
                    />
                </div>

                {/* Preferred Job Location Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Preferred Job Location:', 'jobLocation', jobLocationFilter)}
                    <Autocomplete
                        multiple
                        limitTags={3}
                        disableCloseOnSelect={true}
                        id="job-location-filter"
                        options={onlyJobLocations}
                        // defaultValue={jobLocationFilter}
                        value={jobLocationFilter}
                        size='small'
                        freeSolo={true}
                        onChange={(e, newValue) => handleFilterChange("jobLocation", newValue)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return (
                                    <Chip variant="filled" label={option} key={key} {...tagProps} />
                                );
                            })
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                // label="Work Experiences:"
                                placeholder="..."
                            />
                        )}
                        sx={customAutocompleteStyle(jobLocationFilter)}
                    />
                </div>

                {/* Non App User Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('App / Non App User:', 'userType', userTypeFilter)}
                    <Select
                        defaultValue={userTypeFilter}
                        value={userTypeFilter}
                        placeholder="..."
                        options={constants.userType}
                        onChange={(e) => handleFilterChange("userType", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(userTypeFilter)}
                    />
                </div>

                {/* Active Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Account Deletion Request:', 'active', activeFilter)}
                    <Select
                        defaultValue={activeFilter}
                        value={activeFilter}
                        placeholder="..."
                        options={constants.accountDeleteRequestBool}
                        onChange={(e) => handleFilterChange("active", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(activeFilter)}
                    />
                </div>

                {/* Actively Looking for Job Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Actively Looking for Job:', 'lookingForJob', lookingForJobFilter)}
                    <Select
                        defaultValue={lookingForJobFilter}
                        value={lookingForJobFilter}
                        placeholder="..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("lookingForJob", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(lookingForJobFilter)}
                    />
                </div>

                {/* Profile Incomplete Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Profile Incomplete:', 'profileIncomplete', profileIncompleteFilter)}
                    <Select
                        defaultValue={profileIncompleteFilter}
                        value={profileIncompleteFilter}
                        placeholder="..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("profileIncomplete", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(profileIncompleteFilter)}
                    />
                </div>

                {/* Salary Range Filter Section */}
                <div style={{ marginTop: '8px' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className='canFilterLabel'>Salary Range:</div>
                        <Button sx={{ textTransform: 'none' }} size='small' variant="text"
                            disabled={!lowerSalaryFilter && !upperSalaryFilter}
                            onClick={() => handleResetSalaryRangeFilter()}>
                            Reset
                        </Button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                        <TextField
                            placeholder='lower'
                            type="number"
                            size="small"
                            value={lowerSalaryInput}
                            onChange={(e) => setLowerSalaryInput(e.target.value)}
                            onKeyDown={(e) => handleOnKeyDownSalaryRange(e, 'salaryLowerRange')}
                            sx={customInputStyle(lowerSalaryFilter)}
                            error={lowerSalaryInput && upperSalaryInput && Number(lowerSalaryInput) > Number(upperSalaryInput)}
                        />
                        <TextField
                            placeholder='upper'
                            type="number"
                            size="small"
                            value={upperSalaryInput}
                            onChange={(e) => setUpperSalaryInput(e.target.value)}
                            onKeyDown={(e) => handleOnKeyDownSalaryRange(e, 'salaryUpperRange')}
                            sx={customInputStyle(upperSalaryFilter)}
                            error={lowerSalaryInput && upperSalaryInput && Number(lowerSalaryInput) > Number(upperSalaryInput)}
                        />
                    </div>
                </div>

                {/* Create Date Range Filter Section */}
                <div style={{ marginTop: '8px' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className='canFilterLabel'>Create Date Range:</div>
                        <Button sx={{ textTransform: 'none' }} size='small' variant="text"
                            disabled={!fromCreateDateFilter && !toCreateDateFilter}
                            onClick={() => handleResetCreateDateRangeFilter()}>
                            Reset
                        </Button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                        <DatePicker
                            selected={fromCreateDateFilter}
                            onChange={(date) => handleFilterChange("fromCreateDate", date)}
                            dateFormat='dd/MM/yyyy'
                            placeholderText='From'
                            autoComplete='off'
                            selectsStart
                            startDate={fromCreateDateFilter}
                            endDate={toCreateDateFilter}
                            maxDate={toCreateDateFilter}
                            customInput={
                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    style={{
                                        width: '100% !important',
                                    }}
                                    size='small'
                                    sx={customInputStyle(fromCreateDateFilter)}
                                />
                            }
                        />
                        <DatePicker
                            selected={toCreateDateFilter}
                            onChange={(date) => handleFilterChange("toCreateDate", date)}
                            dateFormat='dd/MM/yyyy'
                            placeholderText='To'
                            autoComplete='off'
                            selectsEnd
                            startDate={fromCreateDateFilter}
                            endDate={toCreateDateFilter}
                            minDate={fromCreateDateFilter}
                            customInput={
                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    style={{
                                        width: '100% !important',
                                    }}
                                    size='small'
                                    sx={customInputStyle(toCreateDateFilter)}
                                />
                            }
                        />
                    </div>
                </div>

                {/* Update Date Filter Section */}
                <div style={{ marginTop: '8px' }} className='filterFullWidthDatePicker'>
                    {FilterHeader('Modified Date:', 'updateDate', updateDateFilter)}
                    <DatePicker
                        selected={updateDateFilter}
                        onChange={(date) => handleFilterChange("updateDate", date)}
                        dateFormat='dd/MM/yyyy'
                        placeholderText='...'
                        autoComplete='off'
                        customInput={
                            <TextField
                                autoComplete='off'
                                fullWidth
                                style={{
                                    width: '100% !important',
                                }}
                                size='small'
                                sx={customInputStyle(updateDateFilter)}
                            />
                        }
                    />
                </div>


            </div>

        </div>


    );
};

export default CandidateFilters;