import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import TextFieldComponent from '../components/TextFieldComponent';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployerData, getEmployeesList, getSingleEmployerData, setEmployerFilters } from '../redux/actions/employerActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EmployerProfileModal from '../models/EmployerProfile';
import moment from 'moment';
import EmployerForm from './EmployerForm'
import { useNavigate } from 'react-router-dom';
import JobPostForm from './JobPostForm';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GigPostForm from '../pages/GigPostForm';
import PaidIcon from '@mui/icons-material/Paid';
import './css/Button.css';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { isSuperUser } from '../redux/selectors/userSelector';
import DocumentUploadModal from '../models/DocumentUploadModal';
import constants from '../helpers/constants.json';
import { Add, ArrowBackIosNew, ExpandMore, FilterAlt, Send } from '@mui/icons-material';
import ExportComponent from '../components/ExportComponent';
import { Checkbox, IconButton } from '@mui/material';
import EmployerFilters from './EmployerFilters';
import HeaderComponent from '../components/HeaderComponent';
import PaginationComponent from '../components/PaginationComponent';
import SendNotificationModal from '../models/SendNotificationModal';


const ViewEmployers = () => {

  const MENU_ID = 'EMPLOYER_ACTION';
  const SELECT_MENU_ID = 'EMPLOYER_SELECTION';

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedEmployerDetail, setSelectedEmployerDetail] = useState(null);

  const isMountingRef = useRef(false);

  const [employer, setEmployer] = useState(null);

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0, // default to the first page
    searchText: ''
  });

  const messageStyle = {
    color: 'green',
    margin: "16px 0",
    fontWeight: 'bold',
  };

  const { show } = useContextMenu();

  const handleContextMenu = (event, employer, index) => {
    setSelectedRow(index);
    show({
      id: MENU_ID,
      event,
      props: employer,
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const superUser = useSelector(isSuperUser);

  const employersResponse = useSelector((state) => state.employer.employer);
  const employers = employersResponse?.data;

  const totalRecords = employersResponse?.metadata?.totalRecords;
  const totalPages = employersResponse?.metadata?.totalPages;

  const employerDetails = useSelector((state) => state.employer.employerById);
  const employerDataById = employerDetails?.data;

  const employerFilters = useSelector((state) => state.employer.employerFilters);

  const [searchTerm, setSearchTerm] = useState('');
  const [isAddEmployerVisible, setAddEmployerVisible] = useState(false);
  const [isEmployerDetailVisible, setIsEmployerDetailVisible] = useState(false);
  const [isAddJobVisible, setAddJobVisible] = useState(false);
  const [isAddGigVisible, setAddGigVisible] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [employerId, setEmployerId] = useState(null);
  const [isDocumentUploadModal, setDocumentUploadModal] = useState(false);
  const [isEmployerFiltersOpen, setIsEmployerFiltersOpen] = useState(false);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);
  const [isAllEmployersSelected, setIsAllEmployersSelected] = useState(false);
  const [excludedEmployerIds, setExcludedEmployerIds] = useState([]);

  const notificationTargetUserCount = isAllEmployersSelected ? (totalRecords - excludedEmployerIds.length) : (selectedEmployers.length);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    let aParams = { ...queryParams, ...employerFilters };
    dispatch(getEmployeesList(aParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (!isMountingRef.current) {
      setQueryParams((prevParams) => ({ ...prevParams, pageNumber: 0 }));
    } else {
      isMountingRef.current = false;
    }
  }, [employerFilters]);


  const handleAddEmployerClick = () => {
    setSelectedEmployerDetail(null);
    setAddEmployerVisible(!isAddEmployerVisible);
    setAddGigVisible(false);
    setAddJobVisible(false);
  };

  const handleEditClick = (employer) => {
    setSelectedEmployerDetail(employer)

    setAddEmployerVisible(true);
  };

  const handleViewMore = (id) => {
    dispatch(getSingleEmployerData(id));
    setIsEmployerDetailVisible(true);
  };

  const handleDeleteClick = (id) => {
    setEmployerId(id);
    setShowDeleteDialog(true)
  };

  const handlePageChange = (event, page) => {
    handleCloseEmployerOrJobModal();
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
  };

  const handleCloseEmployerOrJobModal = () => {
    setEmployer(null);
    setAddJobVisible(false);
    setAddEmployerVisible(false);
    setIsEmployerDetailVisible(false);
    setAddGigVisible(false);
  };

  const handleCloseAllForm = () => {
    setEmployer(null);
    setAddJobVisible(false);
    setAddEmployerVisible(false);
    setAddGigVisible(false);
  }

  const handleSearch = (text) => {
    handleCloseEmployerOrJobModal();
    setSearchTerm(text);
    clearTimeout(typingTimeout); // Clear the previous timeout
    const timeout = setTimeout(() => {
      setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
    }, 700);
    setTypingTimeout(timeout);
  };

  const handleAddJobClick = (employer) => {
    setAddJobVisible(true);
    setAddGigVisible(false)
    setAddEmployerVisible(false);
    setEmployer(employer);
  };

  const handleAddGigsClick = (employer) => {
    setAddGigVisible(true);
    setAddJobVisible(false);
    setAddEmployerVisible(false);
    setEmployer(employer);
  }

  const handleNavigateJobs = (employerId) => {
    // Navigate to the 'jobs' route with the 'employerId' as a query parameter
    navigate(`/app/jobs?employerId=${employerId}`);
  };

  const handleNavigateGigs = (employerId) => {
    // Navigate to the 'jobs' route with the 'employerId' as a query parameter
    navigate(`/app/gigs?employerId=${employerId}`);
  };

  const handleConfirmationDialogClose = () => {
    setEmployerId(null);
    setShowDeleteDialog(false)
  };

  const handleConfirmationDialog = () => {
    dispatch(deleteEmployerData(employerId))
    setShowDeleteDialog(false);
    setEmployerId(null);
    setSelectedRow(null);
  };

  const handleUploadDocumentModal = (employer) => {
    setDocumentUploadModal(true)
    setEmployerId(employer.employerId);
  };

  const handleCloseUploadDocumentModal = () => {
    setDocumentUploadModal(false);
    setEmployerId(null);
  }

  const handleFilterClick = () => {
    setIsEmployerFiltersOpen(true);
  }

  const handleCloseEmployerFilters = () => {
    setIsEmployerFiltersOpen(false);
  }

  const handleItemClick = ({ event, id }) => {
    const employer = event.props
    const { employerId } = event.props;
    switch (id) {
      case 'upload_document':
        handleUploadDocumentModal(employer)
        break;
      case "view_more":
        handleViewMore(employerId)
        break;
      case "view_jobs":
        handleNavigateJobs(employerId)
        break;
      case "view_gigs":
        handleNavigateGigs(employerId);
        break;
      case "add_job":
        handleAddJobClick(employer)
        break;
      case "add_gig":
        handleAddGigsClick(employer)
        break;
      case "edit":
        handleEditClick(employer)
        break;
      case "delete":
        handleDeleteClick(employerId)
        break;
      default:
        break
    }
  }

  const handleSelectedEmployerChange = (employer) => {
    if (isAllEmployersSelected) {
      setExcludedEmployerIds(prevState => [...prevState, employer.employerId]);
    } else {
      setSelectedEmployers(prevSelectedEmployers => {
        if (prevSelectedEmployers.some(e => e.employerId === employer.employerId)) {
          // If the employer is already selected, remove it from the selected employers list
          return prevSelectedEmployers.filter(e => e.employerId !== employer.employerId);
        } else {
          // If the employer is not selected, add it to the selected employers list
          return [...prevSelectedEmployers, employer];
        }
      });
    }
  };

  const showSelectContextMenu = (e) => {
    show({ id: SELECT_MENU_ID, event: e })
  }

  const handleSelectVisible = () => {
    setSelectedEmployers(employers);
  }

  const handleSelectAll = () => {
    setSelectedEmployers([]);
    setIsAllEmployersSelected(true);
  }

  const handleClearSelection = () => {
    setSelectedEmployers([]);
    setExcludedEmployerIds([]);
    setIsAllEmployersSelected(false);
  }

  const handleSendNotification = () => {
    setIsSendNotificationModalOpen(true);
  }

  const handleCloseSendNotificationModal = () => {
    setIsSendNotificationModalOpen(false);
  }

  const handleClearAllFilters = () => {
    let empFilters = {
      sort: '',
      active: '',
      verified: '',
      assistedHire: '',
      profileIncomplete: '',
      fromCreateDate: '',
      toCreateDate: '',
      updateDate: ''
    };

    empFilters['isApplied'] = false;

    dispatch(setEmployerFilters(empFilters));

  }

  return (
    <>
      <HeaderComponent name="Employers" totalRecords={totalRecords} />
      <Grid container spacing={3}>
        {/* Left side: Employers Table */}
        <Grid size={{ xs: 12, md: isAddEmployerVisible || isAddJobVisible || isAddGigVisible ? 8 : (isEmployerFiltersOpen ? 9 : 12) }}>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {/* Search Text Field */}
              <div>
                <TextFieldComponent
                  label="Search"
                  type="search"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{ width: '350px' }}
                />
              </div>


              <div style={{ display: 'flex' }}>
                {superUser && (
                  <ExportComponent
                    screenName={constants.screenName.EMPLOYER}
                    filters={{ ...employerFilters, 'searchText': queryParams.searchText }}
                    totalRecords={totalRecords}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '8px', alignItems: 'center' }}>

              {(isAllEmployersSelected || selectedEmployers.length > 0) &&
                <div style={{ whiteSpace: 'nowrap', marginRight: '8px' }}>
                  <Button variant="contained" color="primary" onClick={handleSendNotification}>
                    <Send style={{ marginRight: '8px' }} />
                    Send Notification ({notificationTargetUserCount})
                  </Button>
                </div>
              }

              {/* Filter Button */}
              <Tooltip title="Filter">
                <IconButton
                  style={{ marginLeft: '8px' }}
                  sx={{
                    backgroundColor: "#1976d2",
                    color: 'white',
                    ':hover': {
                      backgroundColor: 'primary.light'
                    },
                  }}
                  onClick={handleFilterClick}>
                  <FilterAlt />
                  {employerFilters.isApplied &&
                    <span style={{
                      position: 'absolute',
                      borderRadius: '50%',
                      height: '14px',
                      width: '14px',
                      backgroundColor: 'red',
                      opacity: '80%',
                      top: 0,
                      right: 0
                    }} />
                  }
                </IconButton>
              </Tooltip>

              {/* Add Employer Button */}
              <Tooltip title="Add Employer">
                <IconButton
                  style={{ marginLeft: '8px' }}
                  sx={{
                    backgroundColor: "#1976d2",
                    color: 'white',
                    ':hover': {
                      backgroundColor: 'primary.light'
                    },
                  }}
                  onClick={handleAddEmployerClick}>
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
          </div>


          {/* Filter Applied Info Section */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px', marginBottom: '8px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
            {employerFilters.isApplied &&
              <>
                <b>Filters are applied.</b>
                <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                  onClick={handleClearAllFilters}>
                  Clear All Filters
                </Button>
              </>
            }
          </div>

          {/* Select All and Clear Selection Section */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px', marginBottom: '8px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
            {!isAllEmployersSelected && selectedEmployers.length > 0 &&
              <>
                <div><b>{notificationTargetUserCount}</b> employers are selected.</div>
                <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                  onClick={handleSelectAll}>
                  Select all {totalRecords} employers
                </Button>
              </>
            }
            {isAllEmployersSelected &&
              <>
                <div>All <b>{notificationTargetUserCount}</b> employers are selected.</div>
                <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                  onClick={handleClearSelection}>
                  Clear selection
                </Button>
              </>
            }
            {selectedEmployers.length > 0 &&
              <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                onClick={handleClearSelection}>
                Clear selection
              </Button>
            }
          </div>

          <TableContainer component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: 0, textAlign: 'center' }}>
                    <IconButton onClick={showSelectContextMenu}><ExpandMore /></IconButton>
                  </TableCell>
                  <TableCell style={styles.textBold}>Name</TableCell>
                  <TableCell style={styles.textBold}>Mobile Number</TableCell>
                  <TableCell style={styles.textBold}>Brand Name</TableCell>
                  <TableCell style={styles.textBold}>Company Name</TableCell>
                  <TableCell style={styles.textBold}>Outlet Type</TableCell>
                  <TableCell style={styles.textBold}>Job Posts</TableCell>
                  <TableCell style={styles.textBold}>Verified</TableCell>
                  <TableCell style={styles.textBold}>Assisted Hire</TableCell>
                  <TableCell style={styles.textBold}>Created At</TableCell>
                  <TableCell style={styles.textBold}>Updated At</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {employers?.length > 0 ? (
                  employers.map((employer, index) => (
                    <TableRow onContextMenu={(event) => handleContextMenu(event, employer, index)} key={employer.employerId}
                      onClick={() => setSelectedRow(index)}
                      onDoubleClick={() => handleViewMore(employer.employerId)}
                      style={{
                        backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                      }}
                    >
                      <TableCell style={{ padding: '4px' }}>
                        <Checkbox
                          size='small'
                          checked={isAllEmployersSelected ?
                            !excludedEmployerIds.some((e) => e === employer.employerId)
                            : selectedEmployers.some((e) => e.employerId === employer.employerId)}
                          onChange={() => handleSelectedEmployerChange(employer)}
                        />
                      </TableCell>
                      <TableCell >{employer.name}</TableCell>
                      <TableCell>{employer.mobileNumber}</TableCell>
                      <TableCell>{employer.brandName}</TableCell>
                      <TableCell>{employer.companyName}</TableCell>
                      <TableCell>{employer.outletType}</TableCell>
                      <TableCell>{employer.noOfJobPosts}</TableCell>
                      <TableCell>{employer.verified === false ? 'No' : 'Yes'}</TableCell>
                      <TableCell>{employer.assistedHire === false ? 'No' : 'Yes'}</TableCell>
                      <TableCell>{employer.createDate && moment(employer.createDate).format("DD/MM/YYYY")}</TableCell>
                      <TableCell>{employer.updateDate && moment(employer.updateDate).format("DD/MM/YYYY")}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={16}>
                      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>No Employers</h1>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Menu id={SELECT_MENU_ID} style={{ minWidth: '0px', backgroundColor: '#f2f2f2' }}>
            <Item disabled={isAllEmployersSelected} onClick={handleSelectVisible}>Select Visible</Item>
            <Item onClick={handleSelectAll}>Select All</Item>
          </Menu>

          <Menu id={MENU_ID}>
            <Item id="upload_documents" onClick={(event, props) => handleItemClick({ event, props, id: 'upload_document' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <DocumentScannerIcon />
                <span style={{ marginLeft: '5px' }}>Upload Documents / Images</span>
              </div>
            </Item>
            <Separator />
            <Item id="view_more" onClick={(event, props) => handleItemClick({ event, props, id: 'view_more' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <VisibilityIcon />
                <span style={{ marginLeft: '5px' }}>View More</span>
              </div>
            </Item>
            <Item id="view_jobs" onClick={(event) => handleItemClick({ event, id: 'view_jobs' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <WorkIcon />
                <span style={{ marginLeft: '5px' }}>View Jobs</span>
              </div>
            </Item>
            <Item id="view_gigs" onClick={(event) => handleItemClick({ event, id: 'view_gigs' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <PaidIcon />
                <span style={{ marginLeft: '5px' }}>View Gigs</span>
              </div>
            </Item>
            <Separator />
            <Item id="add_job" onClick={(event) => handleItemClick({ event, id: 'add_job' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
                <AddCircleRounded />
                <span style={{ marginLeft: '5px' }}>Add Job</span>
              </div>
            </Item>
            <Item id="add_gig" onClick={(event) => handleItemClick({ event, id: 'add_gig' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <AttachMoneyIcon />
                <span style={{ marginLeft: '5px' }}>Add Gigs</span>
              </div>
            </Item>
            <Separator />
            <Item id="edit" onClick={(event) => handleItemClick({ event, id: 'edit' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <EditIcon />
                <span style={{ marginLeft: '5px' }}>Edit</span>
              </div>
            </Item>
            {superUser && <Item id="delete" onClick={(event) => handleItemClick({ event, id: 'delete' })}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <DeleteIcon />
                <span style={{ marginLeft: '5px' }}>Delete</span>
              </div>
            </Item>}
          </Menu>


          <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
            <PaginationComponent
              pageNumber={queryParams.pageNumber + 1}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Grid>
        </Grid>

        {/* Right side: Add User Button */}
        {
          isAddEmployerVisible && (
            <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
              <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseAllForm} style={{ marginBottom: '16px' }}>
                Back
              </Button>
              <EmployerForm onClose={() => setAddEmployerVisible(false)} employerQueryParams={queryParams} employerDetail={selectedEmployerDetail} />
            </Grid>
          )
        }

        {
          isAddJobVisible && !isAddEmployerVisible && !isAddGigVisible && (
            <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
              <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseAllForm}>
                Back
              </Button>
              <div style={messageStyle}>
                You are adding a job for {employer.name} at {employer.brandName}.
                The job is associated with the company {employer.companyName} and falls under the outlet type {employer.outletType}.
              </div>
              <JobPostForm
                employerId={employer.employerId}
                onClose={handleCloseEmployerOrJobModal}
                isOpen={isAddJobVisible}
              />
            </Grid>
          )
        }

        {
          isAddGigVisible && !isAddEmployerVisible && !isAddJobVisible && (
            <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
              <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseAllForm}>
                Back
              </Button>
              <div style={messageStyle}>
                You are adding a gig for {employer.name} at {employer.brandName}.
                The job is associated with the company {employer.companyName} and falls under the outlet type {employer.outletType}.
              </div>
              <GigPostForm
                employerId={employer.employerId}
                onClose={handleCloseEmployerOrJobModal}
                isOpen={isAddGigVisible}
              />
            </Grid>
          )
        }

        {isEmployerFiltersOpen &&
          <Grid size={{ xs: 12, md: 3 }} style={{ paddingTop: '8px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
            <EmployerFilters
              onClose={handleCloseEmployerFilters}
              filtersData={employerFilters}
            />
          </Grid>
        }

        {
          isEmployerDetailVisible && employerDataById !== null && (
            <EmployerProfileModal
              isOpen={isEmployerDetailVisible}
              onClose={handleCloseEmployerOrJobModal}
              employerData={employerDataById}
            />
          )
        }

        {isSendNotificationModalOpen &&
          <SendNotificationModal
            isOpen={isSendNotificationModalOpen}
            onClose={handleCloseSendNotificationModal}
            isAllUsersSelected={isAllEmployersSelected}
            filters={{ ...employerFilters, 'isCandidate': false, 'searchText': queryParams.searchText }}
            excludedIds={excludedEmployerIds}
            users={selectedEmployers.map(e => ({ 'id': e.employerId, 'name': e.name, 'mobileNumber': e.mobileNumber }))}
            usersCount={notificationTargetUserCount}
          />
        }

        {showDeleteDialog && (
          <ConfirmationDialog
            open={true}
            onClose={handleConfirmationDialogClose}
            onConfirm={handleConfirmationDialog}
            message="Do you want to Delete?"
          />
        )}

        {
          isDocumentUploadModal === true && (
            <DocumentUploadModal documentTypes={constants.employerDocumentTypes} userId={employerId} open={isDocumentUploadModal} onClose={handleCloseUploadDocumentModal} />
          )
        }

      </Grid >
    </>
  );
};

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}
export default ViewEmployers;
