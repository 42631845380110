import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useDispatch, useSelector, } from 'react-redux';
import { getPendingGigsList, updateGigApplicationStatus } from '../redux/actions/gigsActions';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CandidateProfile from '../models/CandidateProfile';
import { getSingleCandidateData } from '../redux/actions/candidateActions';
import { convertToShortYearAndMonth } from '../helpers/utility';
import HeaderComponent from '../components/HeaderComponent';
import PaginationComponent from '../components/PaginationComponent';

const ViewPendingGigApplications = () => {
    const dispatch = useDispatch();
    const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
    const [selectedGig, setSelectedGig] = useState(null);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [gigAlertMessage, setGigAlertMessage] = useState('');
    const [pageNumber, setPageNumber] = useState(0);

    const candidateById = useSelector((state) => state.candidate.candidateById);
    const candidateDataById = candidateById?.data;

    const gigResponse = useSelector((state) => state.gig.pendingGigs);
    const pendingGigsApplication = gigResponse?.data;

    const totalRecords = gigResponse?.metadata?.totalRecords;
    const totalPages = gigResponse?.metadata?.totalPages;

    // Simulating fetching gig applications data
    useEffect(() => {
        dispatch(getPendingGigsList(pageNumber))
    }, [pageNumber, dispatch]);

    const handleApproveClick = (gig) => {
        setSelectedGig(gig)
        setGigAlertMessage('Do you want to approve this gig?');
        setConfirmationDialogOpen(true)
    }

    const handleRejectClick = (gig) => {
        setSelectedGig(gig);
        setGigAlertMessage('Do you want to reject this gig?');
        setConfirmationDialogOpen(true);
    };

    const handleConfirmationDialog = () => {

        const lowerCaseMessage = gigAlertMessage.toLowerCase();

        let body;
        if (lowerCaseMessage.includes('approve')) {
            body = {
                isApproved: true,
                applicationStatus: 'NOT_REVIEWED',
            };
        } else if (lowerCaseMessage.includes('reject')) {
            body = {
                isApproved: false,
                applicationStatus: 'REJECTED',
            };
        } else {
            return;
        }

        dispatch(updateGigApplicationStatus(selectedGig.gigApplicationId, body));
        setConfirmationDialogOpen(false);

    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handlePageChange = (event, page) => {
        setPageNumber(page - 1);
    };

    const handleCloseCandidateModal = () => {
        setIsCandidateModalOpen(false);
    };


    const openCandidateDetailsModal = (candidate) => {
        dispatch(getSingleCandidateData(candidate.candidateId));
        setIsCandidateModalOpen(true)
    }

    return (
        <>
            <HeaderComponent name="Pending Gig Applications" totalRecords={totalRecords} />
            <Grid container spacing={3}>
                <Grid size={{ xs: 12 }}>
                    {pendingGigsApplication?.length > 0 ? (
                        <>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={styles.textBold}>Candidate Name</TableCell>
                                            <TableCell style={styles.textBold}>Location</TableCell>
                                            <TableCell style={styles.textBold}>Job Location</TableCell>
                                            <TableCell style={styles.textBold}>Last Job Title</TableCell>
                                            <TableCell style={styles.textBold}> Last Job Company</TableCell>
                                            <TableCell style={styles.textBold}>Description</TableCell>
                                            <TableCell style={styles.textBold}>Experience</TableCell>
                                            <TableCell style={styles.textBold}>Highest Education</TableCell>
                                            <TableCell style={styles.textBold}>Last Course Name</TableCell>
                                            <TableCell style={styles.textBold}>Applied On Date</TableCell>
                                            <TableCell style={styles.textBold}>Application Status</TableCell>
                                            <TableCell style={styles.textBold}>Application Approved ?</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pendingGigsApplication?.map((gigApplication) => (
                                            <TableRow key={gigApplication.gigApplicationId}>

                                                <TableCell style={{ cursor: 'pointer' }} onClick={() => openCandidateDetailsModal(gigApplication)}>{gigApplication.candidateName}</TableCell>
                                                <TableCell>{gigApplication.location}</TableCell>
                                                <TableCell>{gigApplication.jobLocation}</TableCell>
                                                <TableCell>{gigApplication.lastJobTitle || 'Not specified'}</TableCell>
                                                <TableCell>{gigApplication.lastJobCompany || 'Not specified'}</TableCell>
                                                <TableCell>{gigApplication.description || 'No description provided'}</TableCell>
                                                <TableCell>{convertToShortYearAndMonth(gigApplication.monthsOfExperience) || 'No experience'}</TableCell>
                                                <TableCell>{gigApplication.highestEducation}</TableCell>
                                                <TableCell>{gigApplication.lastCourseName || 'Not specified'}</TableCell>
                                                <TableCell>{moment(gigApplication.appliedOnDate).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{gigApplication.applicationStatus || 'Not specified'}</TableCell>
                                                <TableCell>
                                                    <div style={{ display: 'flex', gap: '8px' }}>
                                                        <Button
                                                            variant="outlined"
                                                            color="success"
                                                            onClick={() => handleApproveClick(gigApplication)}
                                                        >
                                                            Approve
                                                        </Button>

                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() => handleRejectClick(gigApplication)}
                                                        >
                                                            Reject
                                                        </Button>
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
                                <PaginationComponent
                                    pageNumber={pageNumber + 1}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                />
                            </Grid>
                        </>
                    ) : (
                        <h1 style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>No Pending Gig Applications</h1>
                    )}

                    {isCandidateModalOpen && candidateDataById !== null && (
                        <CandidateProfile
                            isOpen={isCandidateModalOpen}
                            onClose={handleCloseCandidateModal}
                            candidateData={candidateDataById}
                        />
                    )}


                    {selectedGig && <Dialog open={confirmationDialogOpen} onClose={handleConfirmationDialogClose}>
                        <DialogTitle>Confirmation</DialogTitle>
                        <DialogContent>
                            {gigAlertMessage}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmationDialogClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirmationDialog} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>}
                </Grid>
            </Grid>
        </>
    );
};

export default ViewPendingGigApplications;

const styles = {
    textBold: {
        fontWeight: 'bold'
    }
}