// JobPostFilters.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import constants from '../helpers/constants.json';
import { Button, Divider, TextField, Tooltip } from '@mui/material';
import DatePicker from 'react-datepicker';
import Select from "react-select";
import { formatYYYYMMDDDate } from '../helpers/formattedDateTime';
import { ArrowDownward, ArrowUpward, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { setJobsFilters } from '../redux/actions/jobActions';

const JobPostFilters = ({ onClose, filtersData }) => {
    const dispatch = useDispatch();

    const jobsFilters = useSelector((state) => state.job.jobsFilters);

    const [sortDirection, setSortDirection] = useState('desc');

    const initSortOrder = filtersData.sort !== '' ? constants.employerSortOrders?.find(option => option.id === (filtersData.sort?.split(":")[0])) : '';
    const [sortOrderFilter, setSortOrderFilter] = useState(initSortOrder);

    const initActive = filtersData.active !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.active) : '';
    const [activeFilter, setActiveFilter] = useState(initActive);

    const initJobExpired = filtersData.jobExpired !== '' ? constants.jobExpiryBool?.find(option => option.id === filtersData.jobExpired) : '';
    const [jobExpiredFilter, setJobExpiredFilter] = useState(initJobExpired);

    const initAssistedHire = filtersData.assistedHire !== '' ? constants.boolValueTypes?.find(option => option.id === filtersData.assistedHire) : '';
    const [assistedHireFilter, setAssistedHireFilter] = useState(initAssistedHire);

    const [lowerSalaryFilter, setLowerSalaryFilter] = useState(filtersData.salaryLowerRange);
    const [upperSalaryFilter, setUpperSalaryFilter] = useState(filtersData.salaryUpperRange);

    const [lowerSalaryInput, setLowerSalaryInput] = useState(filtersData.salaryLowerRange);
    const [upperSalaryInput, setUpperSalaryInput] = useState(filtersData.salaryUpperRange);

    const initFromCreateDate = filtersData.hasOwnProperty("fromCreateDate") && filtersData.fromCreateDate !== '' ? new Date(filtersData.fromCreateDate) : '';
    const [fromCreateDateFilter, setFromCreateDateFilter] = useState(initFromCreateDate);

    const initToCreateDate = filtersData.hasOwnProperty("toCreateDate") && filtersData.toCreateDate !== '' ? new Date(filtersData.toCreateDate) : '';
    const [toCreateDateFilter, setToCreateDateFilter] = useState(initToCreateDate);

    const initUpdateDate = filtersData.hasOwnProperty("updateDate") && filtersData.updateDate !== '' ? new Date(filtersData.updateDate) : '';
    const [updateDateFilter, setUpdateDateFilter] = useState(initUpdateDate);

    const handleResetAll = () => {
        let jFilters = {
            sort: '',
            active: '',
            jobExpired: '',
            assistedHire: '',
            salaryLowerRange: '',
            salaryUpperRange: '',
            fromCreateDate: '',
            toCreateDate: '',
            updateDate: ''
        };

        jFilters['isApplied'] = false;

        dispatch(setJobsFilters(jFilters));

        setSortOrderFilter('');
        setActiveFilter('');
        setJobExpiredFilter('');
        setAssistedHireFilter('');
        setLowerSalaryFilter('');
        setLowerSalaryInput('');
        setUpperSalaryFilter('');
        setUpperSalaryInput('');
        setFromCreateDateFilter('');
        setToCreateDateFilter('');
        setUpdateDateFilter('');

    }

    const handleFilterChange = (filterType, e) => {
        let jFilters = { ...jobsFilters };
        let filterValue = e?.id;
        jFilters[filterType] = filterValue;

        switch (filterType) {
            case 'sort':
                let asortOrder = filterValue + ":" + sortDirection;
                jFilters[filterType] = asortOrder;
                setSortOrderFilter(e);
                break;
            case 'active':
                setActiveFilter(e);
                break;
            case 'jobExpired':
                setJobExpiredFilter(e);
                break;
            case 'assistedHire':
                setAssistedHireFilter(e);
                break;
            case 'salaryLowerRange':
                jFilters[filterType] = e;
                setLowerSalaryFilter(e);
                break;
            case 'salaryUpperRange':
                jFilters[filterType] = e;
                setUpperSalaryFilter(e);
                break;
            case 'fromCreateDate':
                filterValue = e;
                jFilters[filterType] = formatYYYYMMDDDate(filterValue);
                setFromCreateDateFilter(filterValue);
                break;
            case 'toCreateDate':
                filterValue = e;
                jFilters[filterType] = formatYYYYMMDDDate(filterValue);
                setToCreateDateFilter(filterValue);
                break;
            case 'updateDate':
                filterValue = e;
                jFilters[filterType] = formatYYYYMMDDDate(filterValue);
                setUpdateDateFilter(filterValue);
                break;
            default:
                break;
        }

        jFilters['isApplied'] = checkIfAnyFilterIsApplied(jFilters);

        dispatch(setJobsFilters(jFilters));
    }

    const isBoolean = (str) => {
        return (typeof str == 'boolean');
    }

    const checkIfAnyFilterIsApplied = (filters) => {
        return (filters.sort || isBoolean(filters.active) || isBoolean(filters.jobExpired) || isBoolean(filters.assistedHire) ||
            filters.fromCreateDate || filters.toCreateDate || filters.updateDate ||
            filters.salaryLowerRange || filters.salaryUpperRange) ? true : false;
    }

    const handleResetSpecific = (filterType) => {
        let jFilters = { ...jobsFilters };

        jFilters[filterType] = '';
        switch (filterType) {
            case 'sort':
                setSortOrderFilter('');
                break;
            case 'active':
                setActiveFilter('');
                break;
            case 'jobExpired':
                setJobExpiredFilter('');
                break;
            case 'assistedHire':
                setAssistedHireFilter('');
                break;
            case 'updateDate':
                setUpdateDateFilter('');
                break;
            default:
                break;
        }

        jFilters['isApplied'] = checkIfAnyFilterIsApplied(jFilters);

        dispatch(setJobsFilters(jFilters));
    }

    const handleResetSalaryRangeFilter = () => {
        let jFilters = { ...jobsFilters };
        jFilters['salaryLowerRange'] = '';
        jFilters['salaryUpperRange'] = '';
        setLowerSalaryInput('');
        setLowerSalaryFilter('');
        setUpperSalaryInput('');
        setUpperSalaryFilter('');

        jFilters['isApplied'] = checkIfAnyFilterIsApplied(jFilters);

        dispatch(setJobsFilters(jFilters));
    }

    const handleResetCreateDateRangeFilter = () => {
        let jFilters = { ...jobsFilters };
        jFilters['fromCreateDate'] = '';
        jFilters['toCreateDate'] = '';
        setFromCreateDateFilter('');
        setToCreateDateFilter('');

        jFilters['isApplied'] = checkIfAnyFilterIsApplied(jFilters);

        dispatch(setJobsFilters(jFilters));
    }

    const changeSortDirection = () => {
        let aDirection = 'asc';
        if (sortDirection == 'asc') {
            aDirection = 'desc';
        }

        let asortOrder = sortOrderFilter?.id + ":" + aDirection;
        let jFilters = { ...jobsFilters };
        jFilters['sort'] = asortOrder;

        setSortDirection(aDirection);
        dispatch(setJobsFilters(jFilters));
    }

    const shouldDisableResetButton = (filterType, filterState) => {
        let shouldDisable = true;
        switch (filterType) {
            case 'sort':
            case 'active':
            case 'jobExpired':
            case 'assistedHire':
                shouldDisable = !filterState?.hasOwnProperty('id');
                break;
            case 'updateDate':
                shouldDisable = !Boolean(filterState);
                break;
            default:
                break;
        }
        return shouldDisable;
    }

    const getReadableSortDirection = () => {
        let readableDirection = (sortDirection == 'asc') ? '(Ascending)' : '(Descending)';
        return readableDirection;
    }

    const handleOnKeyDownSalaryRange = (e, filterType) => {
        if (e.keyCode == 13) {
            let inputValue = e.target.value;
            handleFilterChange(filterType, inputValue);
        }
    }

    const FilterHeader = (label, filterType, filterState) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className='jFilterLabel'>{label}</div>
                <Button sx={{ textTransform: 'none' }} size='small' variant="text"
                    disabled={shouldDisableResetButton(filterType, filterState)}
                    onClick={() => handleResetSpecific(filterType)}>
                    Reset
                </Button>
            </div>
        )
    }

    const customSelectStyle = (filterState) => ({
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: filterState?.hasOwnProperty('id') ? 'green' : '#ccc',
            boxShadow: filterState?.hasOwnProperty('id')
                ? '0 0 0 1px green'
                : (state.isFocused ? '0 0 0 1px #2684FF' : 'none')
        }),
        // singleValue: (baseStyles) => ({
        //     ...baseStyles,
        //     color: 'green'
        // })
    })

    const customInputStyle = (filterState) => ({
        '& .MuiOutlinedInput-root': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: filterState && 'green',
                borderWidth: filterState && '2px'
            }
        }
    })

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton style={{ marginRight: '8px' }} color="primary" onClick={onClose}>
                    <KeyboardDoubleArrowRight />
                </IconButton>
                <Divider orientation='vertical' flexItem variant='middle' />
                <div style={{ flex: 1, marginLeft: '8px', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Filters:</div>
                <Divider orientation='vertical' flexItem variant='middle' />
                <Button style={{ marginLeft: '8px' }} variant="text" onClick={handleResetAll}>
                    Clear All
                </Button>
            </div>

            <Divider style={{ marginBottom: '16px', marginLeft: '-16px' }} />

            <style>{`
                    .filterFullWidthDatePicker > .react-datepicker-wrapper{
                        width: 100%
                    }
                    .jFilterLabel {
                        font-size: 14px;
                    }
                `}</style>

            <div>

                {/* Sort By Section */}
                <div>
                    {FilterHeader('Sort by: ' + getReadableSortDirection(), 'sort', sortOrderFilter)}
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <Select
                                defaultValue={sortOrderFilter}
                                value={sortOrderFilter}
                                placeholder="..."
                                options={constants.jobsSortOrders}
                                onChange={(e) => handleFilterChange("sort", e)}
                                getOptionLabel={(option) => option?.name.toString() || ""}
                                getOptionValue={(option) => option?.id.toString() || ""}
                                menuPortalTarget={document.body}
                                styles={customSelectStyle(sortOrderFilter)}
                            />
                        </div>
                        {/* Sort By Direction Icon Button */}
                        <Tooltip title={sortDirection == 'asc' ? 'Descending' : 'Ascending'}>
                            <Button
                                variant='outlined'
                                size='small'
                                style={{ marginLeft: '8px', minWidth: '32px' }}
                                onClick={changeSortDirection}
                                disabled={sortOrderFilter == '' || sortOrderFilter == null}
                            >
                                {sortDirection == 'asc' ?
                                    <ArrowDownward />
                                    :
                                    <ArrowUpward />
                                }
                            </Button>
                        </Tooltip>
                    </div>
                </div>

                {/* Active Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Job Active:', 'active', activeFilter)}
                    <Select
                        defaultValue={activeFilter}
                        value={activeFilter}
                        placeholder="..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("active", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(activeFilter)}
                    />
                </div>

                {/* Job Expired Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Job Expired:', 'jobExpired', jobExpiredFilter)}
                    <Select
                        defaultValue={jobExpiredFilter}
                        value={jobExpiredFilter}
                        placeholder="..."
                        options={constants.jobExpiryBool}
                        onChange={(e) => handleFilterChange("jobExpired", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(jobExpiredFilter)}
                    />
                </div>

                {/* Assisted Hire Filter Section */}
                <div style={{ marginTop: '8px' }}>
                    {FilterHeader('Assisted Hire:', 'assistedHire', assistedHireFilter)}
                    <Select
                        defaultValue={assistedHireFilter}
                        value={assistedHireFilter}
                        placeholder="..."
                        options={constants.boolValueTypes}
                        onChange={(e) => handleFilterChange("assistedHire", e)}
                        getOptionLabel={(option) => option?.name.toString() || ""}
                        getOptionValue={(option) => option?.id.toString() || ""}
                        menuPortalTarget={document.body}
                        styles={customSelectStyle(assistedHireFilter)}
                    />
                </div>

                {/* Salary Range Filter Section */}
                <div style={{ marginTop: '8px' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className='jFilterLabel'>Salary Range:</div>
                        <Button sx={{ textTransform: 'none' }} size='small' variant="text"
                            disabled={!lowerSalaryFilter && !upperSalaryFilter}
                            onClick={() => handleResetSalaryRangeFilter()}>
                            Reset
                        </Button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                        <TextField
                            placeholder='lower'
                            type="number"
                            size="small"
                            value={lowerSalaryInput}
                            onChange={(e) => setLowerSalaryInput(e.target.value)}
                            onKeyDown={(e) => handleOnKeyDownSalaryRange(e, 'salaryLowerRange')}
                            sx={customInputStyle(lowerSalaryFilter)}
                            error={lowerSalaryInput && upperSalaryInput && Number(lowerSalaryInput) > Number(upperSalaryInput)}
                        />
                        <TextField
                            placeholder='upper'
                            type="number"
                            size="small"
                            value={upperSalaryInput}
                            onChange={(e) => setUpperSalaryInput(e.target.value)}
                            onKeyDown={(e) => handleOnKeyDownSalaryRange(e, 'salaryUpperRange')}
                            sx={customInputStyle(upperSalaryFilter)}
                            error={lowerSalaryInput && upperSalaryInput && Number(lowerSalaryInput) > Number(upperSalaryInput)}
                        />
                    </div>
                </div>

                {/* Create Date Range Filter Section */}
                <div style={{ marginTop: '8px' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className='jFilterLabel'>Create Date Range:</div>
                        <Button sx={{ textTransform: 'none' }} size='small' variant="text"
                            disabled={!fromCreateDateFilter && !toCreateDateFilter}
                            onClick={() => handleResetCreateDateRangeFilter()}>
                            Reset
                        </Button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                        <DatePicker
                            selected={fromCreateDateFilter}
                            onChange={(date) => handleFilterChange("fromCreateDate", date)}
                            dateFormat='dd/MM/yyyy'
                            placeholderText='From'
                            autoComplete='off'
                            selectsStart
                            startDate={fromCreateDateFilter}
                            endDate={toCreateDateFilter}
                            maxDate={toCreateDateFilter}
                            customInput={
                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    style={{
                                        width: '100% !important',
                                    }}
                                    size='small'
                                    sx={customInputStyle(fromCreateDateFilter)}
                                />
                            }
                        />
                        <DatePicker
                            selected={toCreateDateFilter}
                            onChange={(date) => handleFilterChange("toCreateDate", date)}
                            dateFormat='dd/MM/yyyy'
                            placeholderText='To'
                            autoComplete='off'
                            selectsEnd
                            startDate={fromCreateDateFilter}
                            endDate={toCreateDateFilter}
                            minDate={fromCreateDateFilter}
                            customInput={
                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    style={{
                                        width: '100% !important',
                                    }}
                                    size='small'
                                    sx={customInputStyle(toCreateDateFilter)}
                                />
                            }
                        />
                    </div>
                </div>

                {/* Update Date Filter Section */}
                <div style={{ marginTop: '8px' }} className='filterFullWidthDatePicker'>
                    {FilterHeader('Modified Date:', 'updateDate', updateDateFilter)}
                    <DatePicker
                        selected={updateDateFilter}
                        onChange={(date) => handleFilterChange("updateDate", date)}
                        dateFormat='dd/MM/yyyy'
                        placeholderText='...'
                        autoComplete='off'
                        customInput={
                            <TextField
                                autoComplete='off'
                                fullWidth
                                style={{
                                    width: '100% !important',
                                }}
                                size='small'
                                sx={customInputStyle(updateDateFilter)}
                            />
                        }
                    />
                </div>


            </div>

        </div>


    );
};

export default JobPostFilters;