import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import TextFieldComponent from '../components/TextFieldComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getJobApplicantsList, getJobsList, getSuggestedCandidatesList, getJobByIdDetails, deleteJobRecord, setSelectJobsForJobSuggestion, setJobApplicantsPageNumber, setJobsFilters, bulkUpdateJobPosts } from '../redux/actions/jobActions';
import SuggestedCandidateModal from '../models/SuggestedCandidateModal';
import JobApplicantsModal from '../models/JobApplicantsModal';
import JobPostForm from '../pages/JobPostForm';
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import moment from 'moment';
import JobPostRecordModal from '../models/JobPostRecord';
import { useLocation } from 'react-router-dom';
import ConfirmationDialog from '../components/ConfirmationDialog';
import Button from '@mui/material/Button';
import clipboardService from '../utils/clipboardService';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { isSuperUser } from '../redux/selectors/userSelector';
import AddIcon from '@mui/icons-material/Add';
import SuggestedCandidatesAndJobsModal from '../models/SuggestedCandidatesAndJobsModal';
import constants from '../helpers/constants.json';
import { Add, ArrowBackIosNew, ArrowDropDown, ExpandMore, FilterAlt, Share } from '@mui/icons-material';
import ExportComponent from '../components/ExportComponent';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import JobPostFilters from './JobPostFilters';
import HeaderComponent from '../components/HeaderComponent';
import PaginationComponent from '../components/PaginationComponent';
import ShareLinkComponent from '../components/ShareLinkComponent';
import showToast from '../utils/toast';

const ViewJobs = () => {
  const MENU_ID = 'JOB_ACTION';
  const SELECT_MENU_ID = 'JOB_SELECTION';
  const BULK_ACTION_MENU_ID = 'BULK_ACTION';

  const bulkActionType = {
    "deactivateJobs": "DEACTIVATE_JOBS",
    "deleteJobs": "DELETE_JOBS"
  }

  const [selectedRow, setSelectedRow] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const employerId = searchParams.get('employerId');

  const isMountingRef = useRef(false);
  const actionButtonRef = useRef(null);

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0,
    pageSize: 10,
    searchText: '',
    employerId: employerId ? employerId : '',
  });

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [job, selectedJob] = useState(null);
  const [jobPostId, setJobPostId] = useState(null)
  const [isEditJobVisible, setEditJobVisible] = useState(false);
  const [isSuggestedCandidateModalOpen, setIsSuggestedCandidateModalOpen] = useState(false);
  const [isJobApplicantsModalOpen, setIsJobApplicantsModalOpen] = useState(false);
  const [isMoreDetailsVisible, setIsMoreDetailsVisible] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [isSuggestedCandidateAndJobsModalOpen, setSuggestedCandidateAndJobsModalOpen] = useState(false);
  const [isJobsFiltersOpen, setIsJobsFiltersOpen] = useState(false);
  const [bulkActionConfirmDialog, setBulkActionConfirmDialog] = useState(false);
  const [currentBulkActionType, setcurrentBulkActionType] = useState(null);
  const [bulkActionConfirmTitle, setBulkActionConfirmTitle] = useState("");
  const [bulkActionConfirmMsg, setBulkActionConfirmMsg] = useState("");

  const [isAllJobsSelected, setIsAllJobsSelected] = useState(false);
  const [excludedJobIds, setExcludedJobIds] = useState([]);


  const dispatch = useDispatch();
  const jobsResponse = useSelector((state) => state.job.jobs);
  const jobs = jobsResponse?.data;

  const totalRecords = jobsResponse?.metadata?.totalRecords;
  const totalPages = jobsResponse?.metadata?.totalPages;

  const jobsApplicantsResponse = useSelector((state) => state.job.jobApplicants);
  const jobApplicants = jobsApplicantsResponse;

  const jobMoreDetailsResponse = useSelector((state) => state.job.jobDetailsById);
  const jobMoreDetails = jobMoreDetailsResponse?.data;

  const jobsFilters = useSelector((state) => state.job.jobsFilters);

  const suggestedCandidatesResponse = useSelector((state) => state.job.suggestedCandidates);
  const suggestedCandidates = suggestedCandidatesResponse;

  const hasSuggestedCandidatesForJobSuggestion = useSelector((state) => state.candidate.selectedCandidatesForJobSuggestion?.length > 0);

  const targetJobCount = isAllJobsSelected ? (totalRecords - excludedJobIds.length) : (selectedJobs.length);

  const { show } = useContextMenu();

  const handleContextMenu = (event, job, index) => {
    setSelectedRow(index);
    show({
      id: MENU_ID,
      event,
      props: job,
    });
  };

  const superUser = useSelector(isSuperUser);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    let aParams = { ...queryParams, ...jobsFilters };
    dispatch(getJobsList(aParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (!isMountingRef.current) {
      setQueryParams((prevParams) => ({ ...prevParams, pageNumber: 0 }));
    } else {
      isMountingRef.current = false;
    }
  }, [jobsFilters]);

  const handleEditClick = (job) => {
    selectedJob(null);
    selectedJob(job);
    setEditJobVisible(true);
  };

  const handleSuggestedCandidateClick = (id) => {
    setIsSuggestedCandidateModalOpen(true);
    dispatch(getSuggestedCandidatesList(id));
  };

  const viewMoreDetails = (id) => {
    setIsMoreDetailsVisible(true);
    dispatch(getJobByIdDetails(id));
  };

  const handleJobApplicantsClick = (id) => {
    dispatch(setJobApplicantsPageNumber(0));
    dispatch(getJobApplicantsList(id, 0));
    setIsJobApplicantsModalOpen(true);
  };

  const handleCloseSuggestedCandidateModal = () => {
    setIsSuggestedCandidateModalOpen(false);
  };

  const handleCloseJobApplicantsModal = () => {
    setIsJobApplicantsModalOpen(false);
  };

  const handleCloseJobMoreDetailsModal = () => {
    setIsMoreDetailsVisible(false);
  };

  const handleDeleteClick = (job) => {
    selectedJob(job)
    setShowDeleteDialog(true);
  };

  const handlePageChange = (event, page) => {
    handleCloseJobModal();
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
  };

  const handleSearch = (text) => {
    handleCloseJobModal();
    setSearchTerm(text);
    clearTimeout(typingTimeout);
    const timeout = setTimeout(() => {
      setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
    }, 700);
    setTypingTimeout(timeout);
  };

  const handleCloseJobModal = () => {
    setEditJobVisible(false);
    selectedJob(null);
  };


  const handleConfirmationDialogClose = () => {
    setShowDeleteDialog(false);
    selectedJob(null)
    setSelectedRow(null);
  };

  const handleConfirmationDialog = () => {
    dispatch(deleteJobRecord(job.jobPostId))
    setShowDeleteDialog(false);
    selectedJob(null);
    setSelectedRow(null);
  };

  const handleCopyClick = (shareLink) => {
    clipboardService.copyToClipboard(shareLink);
  };

  const handleFilterClick = () => {
    setIsJobsFiltersOpen(true);
  }

  const handleCloseJobsFilters = () => {
    setIsJobsFiltersOpen(false);
  }

  const handleItemClick = ({ event, id }) => {
    const job = event.props
    const { jobPostId } = event.props;
    setJobPostId(jobPostId);

    switch (id) {
      case "view_more":
        viewMoreDetails(jobPostId)
        break;
      case "suggested_candidate":
        handleSuggestedCandidateClick(jobPostId)
        break;
      case "job_applicants":
        handleJobApplicantsClick(jobPostId)
        break;
      case "edit":
        handleEditClick(job);
        break;
      case "delete":
        setEditJobVisible(false);
        handleDeleteClick(job)
        break;
      default:
        break
    }
  }

  const handleSelectedJobChange = (job) => {
    if (isAllJobsSelected) {
      setExcludedJobIds(prevState => [...prevState, job.jobPostId]);
    } else {
      setSelectedJobs(prevSelectedJobPosts => {
        if (prevSelectedJobPosts.some(c => c.jobPostId === job.jobPostId)) {
          // If the job is already selected, remove it from the selected candidates list
          return prevSelectedJobPosts.filter(c => c.jobPostId !== job.jobPostId);
        } else {
          // If the job is not selected, add it to the selected candidates list
          return [...prevSelectedJobPosts, job];
        }
      });
    }
  }

  const showSelectContextMenu = (e) => {
    show({ id: SELECT_MENU_ID, event: e })
  }

  const handleSelectVisible = () => {
    setSelectedJobs(jobs);
  }

  const handleSelectAll = () => {
    setSelectedJobs([]);
    setIsAllJobsSelected(true);
  }

  const handleClearSelection = () => {
    setSelectedJobs([]);
    setExcludedJobIds([]);
    setIsAllJobsSelected(false);
  }

  const handleBulkActionButton = (e) => {
    show({ id: BULK_ACTION_MENU_ID, event: e, position: getMenuPosition() })
  }

  const getMenuPosition = () => {
    const { left, bottom } = actionButtonRef.current.getBoundingClientRect();
    return { x: left, y: bottom + 4 };
  }

  const handleBulkAction = (actionType) => {
    setcurrentBulkActionType(actionType);
    let title = "", msg = "";
    if (actionType == bulkActionType.deactivateJobs) {
      title = "Bulk Action Confirmation";
      msg = "Are you sure, you want to Deactivate " + targetJobCount + " jobs?";
    } else if (actionType == bulkActionType.deleteJobs) {
      title = "Bulk Action Confirmation";
      msg = "Are you sure, you want to Delete " + targetJobCount + " jobs?";
    }
    setBulkActionConfirmTitle(title);
    setBulkActionConfirmMsg(msg);
    setBulkActionConfirmDialog(true);
  }

  const handleBulkActionConfirmDialogClose = () => {
    setBulkActionConfirmDialog(false);
  }

  const handleBulkActionConfirmDialog = async () => {
    setBulkActionConfirmDialog(false);

    const tempJobIds = selectedJobs.map(item => item.jobPostId);
    let jobData = {
      "actionType": currentBulkActionType
    }

    if (currentBulkActionType == bulkActionType.deactivateJobs) {
      jobData['actionValue'] = false;
    }

    let jFilters = { ...jobsFilters };
    jFilters['searchText'] = queryParams.searchText;
    jFilters['employerId'] = queryParams.employerId;

    if (isAllJobsSelected) {
      jobData['selectAll'] = true;
      jobData['filters'] = jFilters;
      jobData['excludedIds'] = excludedJobIds;
    } else {
      jobData['jobs'] = tempJobIds;
    }

    try {
      await dispatch(bulkUpdateJobPosts(jobData));

      let aParams = { ...queryParams, ...jobsFilters };
      dispatch(getJobsList(aParams));
    } catch (err) {

    }
  }

  const openSuggestedCandidateAndJobsModal = () => {
    dispatch(setSelectJobsForJobSuggestion(selectedJobs))
    setSuggestedCandidateAndJobsModalOpen(true);
  }

  const handleCloseCandidateAndJobsModalOpen = () => {
    setSuggestedCandidateAndJobsModalOpen(false);
  }

  const handleClearAllFilters = () => {
    let jFilters = {
      sort: '',
      active: '',
      jobExpired: '',
      assistedHire: '',
      salaryLowerRange: '',
      salaryUpperRange: '',
      fromCreateDate: '',
      toCreateDate: '',
      updateDate: ''
    };

    jFilters['isApplied'] = false;

    dispatch(setJobsFilters(jFilters));

  }

  return (
    <>
      <HeaderComponent name="Jobs" totalRecords={totalRecords} />
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: isEditJobVisible ? 8 : (isJobsFiltersOpen ? 9 : 12) }} >

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {/* Search Text Field */}
              <div>
                <TextFieldComponent
                  label="Search"
                  type="search"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{ width: '350px' }}
                />
              </div>

              {superUser && (
                <ExportComponent
                  screenName={constants.screenName.JOB_POST}
                  filters={{ ...jobsFilters, 'searchText': queryParams.searchText }}
                  totalRecords={totalRecords}
                  style={{ marginLeft: '10px' }}
                />
              )}

            </div>

            <div>

              {/* Bulk Actions Button */}
              {(isAllJobsSelected || selectedJobs.length > 0) && (
                <Button ref={actionButtonRef} variant="contained" color="primary" onClick={handleBulkActionButton} style={{ whiteSpace: 'nowrap', marginRight: '8px' }}>
                  Actions ({targetJobCount})
                  <ArrowDropDown />
                </Button>
              )}

              {/* Create Suggested Candidates Button */}
              {hasSuggestedCandidatesForJobSuggestion && selectedJobs.length > 0 && (
                <Button variant="contained" color="primary" onClick={() => openSuggestedCandidateAndJobsModal()} style={{ whiteSpace: 'nowrap' }}>
                  <AddIcon style={{ marginRight: '8px' }} />
                  Suggest Candidates
                </Button>
              )}

              {/* Filter Button */}
              <Tooltip title="Filter">
                <IconButton
                  style={{ marginLeft: '8px' }}
                  sx={{
                    backgroundColor: "#1976d2",
                    color: 'white',
                    ':hover': {
                      backgroundColor: 'primary.light'
                    },
                  }}
                  onClick={handleFilterClick}>
                  <FilterAlt />
                  {jobsFilters.isApplied &&
                    <span style={{
                      position: 'absolute',
                      borderRadius: '50%',
                      height: '14px',
                      width: '14px',
                      backgroundColor: 'red',
                      opacity: '80%',
                      top: 0,
                      right: 0
                    }} />
                  }
                </IconButton>
              </Tooltip>
            </div>

          </div>

          {/* Filter Applied Info Section */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px', marginBottom: '8px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
            {jobsFilters.isApplied &&
              <>
                <b>Filters are applied.</b>
                <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                  onClick={handleClearAllFilters}>
                  Clear All Filters
                </Button>
              </>
            }
          </div>

          {/* Select All and Clear Selection Section */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px', marginBottom: '8px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
            {!isAllJobsSelected && selectedJobs.length > 0 &&
              <>
                <div><b>{targetJobCount}</b> jobs are selected.</div>

                {!hasSuggestedCandidatesForJobSuggestion &&
                  <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                    onClick={handleSelectAll}>
                    Select all {totalRecords} jobs
                  </Button>
                }
              </>
            }
            {isAllJobsSelected &&
              <>
                <div>All <b>{targetJobCount}</b> jobs are selected.</div>
                <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                  onClick={handleClearSelection}>
                  Clear selection
                </Button>
              </>
            }
            {selectedJobs.length > 0 &&
              <Button sx={{ textTransform: 'none' }} style={{ marginLeft: '8px', fontWeight: 'bold' }}
                onClick={handleClearSelection}>
                Clear selection
              </Button>
            }
          </div>


          {jobs?.length ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: 0, textAlign: 'center' }}>
                        <IconButton onClick={showSelectContextMenu}><ExpandMore /></IconButton>
                      </TableCell>
                      <TableCell style={styles.textBold}>Job Title</TableCell>
                      <TableCell style={styles.textBold}>Employer Brand Name</TableCell>
                      <TableCell style={styles.textBold}>Job Location</TableCell>
                      <TableCell style={styles.textBold}>Salary Range</TableCell>
                      <TableCell style={styles.textBold}>Accommodation Provided</TableCell>
                      <TableCell style={styles.textBold}>Shift Timing</TableCell>
                      <TableCell style={styles.textBold}>Job Applicants</TableCell>
                      <TableCell style={styles.textBold}>Created At</TableCell>
                      <TableCell style={styles.textBold}>Updated At</TableCell>
                      <TableCell style={styles.textBold}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobs?.map((job, index) => (
                      <TableRow onContextMenu={(event) => handleContextMenu(event, job, index)} key={job.jobPostId}
                        onDoubleClick={() => viewMoreDetails(job.jobPostId)}
                        onClick={() => setSelectedRow(index)}
                        style={{
                          backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                        }}
                      >
                        <TableCell style={{ padding: '4px' }}>
                          <Checkbox
                            size='small'
                            checked={isAllJobsSelected ?
                              !excludedJobIds.some((e) => e === job.jobPostId)
                              : selectedJobs.some((c) => c.jobPostId === job.jobPostId)}
                            onChange={() => handleSelectedJobChange(job)}
                          />
                        </TableCell>
                        <TableCell>{job.jobTitle}</TableCell>
                        <TableCell style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>{job.employerBrandName}</TableCell>
                        <TableCell>{job.jobLocation}</TableCell>
                        <TableCell>{`${formatIndianCurrency(job.salaryLowerRange)} - ${formatIndianCurrency(
                          job.salaryUpperRange
                        )}`}</TableCell>
                        <TableCell>{job.provideAccomodation ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{`${job.shiftStartTime} - ${job.shiftEndTime}`}</TableCell>
                        <TableCell>{job.noOfJobApplications}</TableCell>
                        <TableCell>{job.createDate && moment(job.createDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{job.updateDate && moment(job.updateDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell style={{ padding: '4px' }}>
                          <ShareLinkComponent
                            entityId={job.jobPostId}
                            shareLinkType={constants.entityType.JOB_POST}
                          />
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Menu id={BULK_ACTION_MENU_ID} style={{ minWidth: '0px', backgroundColor: '#f2f2f2' }}>
                <Item onClick={() => handleBulkAction(bulkActionType.deactivateJobs)}>Deactivate Jobs</Item>
                {/* <Item onClick={() => handleBulkAction(bulkActionType.deactivateJobs)}>Delete Jobs</Item> */}
              </Menu>

              <Menu id={SELECT_MENU_ID} style={{ minWidth: '0px', backgroundColor: '#f2f2f2' }}>
                <Item disabled={isAllJobsSelected} onClick={handleSelectVisible}>Select Visible</Item>
                {!hasSuggestedCandidatesForJobSuggestion && <Item onClick={handleSelectAll}>Select All</Item>}
              </Menu>

              <Menu id={MENU_ID}>
                <Item id="view_more" onClick={(event, props) => handleItemClick({ event, props, id: 'view_more' })}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <VisibilityIcon />
                    <span style={{ marginLeft: '5px' }}>View More</span>
                  </div>
                </Item>
                <Item id="suggested_candidate" onClick={(event) => handleItemClick({ event, id: 'suggested_candidate' })}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <LightbulbIcon />
                    <span style={{ marginLeft: '5px' }}>Suggested Candidate</span>
                  </div>
                </Item>

                <Item id="job_applicants" onClick={(event) => handleItemClick({ event, id: 'job_applicants' })}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <PeopleIcon />
                    <span style={{ marginLeft: '5px' }}>Job Applicants</span>
                  </div>
                </Item>
                <Separator />
                <Item id="edit" onClick={(event) => handleItemClick({ event, id: 'edit' })}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <EditIcon />
                    <span style={{ marginLeft: '5px' }}>Edit</span>
                  </div>
                </Item>
                {superUser && <Item id="delete" onClick={(event) => handleItemClick({ event, id: 'delete' })}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
                    <DeleteIcon />
                    <span style={{ marginLeft: '5px' }}>Delete</span>
                  </div>
                </Item>}

              </Menu>

              <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
                <PaginationComponent
                  pageNumber={queryParams.pageNumber + 1}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </Grid>
            </>
          ) : (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <h1>No Jobs</h1>
            </div>
          )}

          {isSuggestedCandidateModalOpen && suggestedCandidates !== null && (
            <SuggestedCandidateModal
              isOpen={isSuggestedCandidateModalOpen}
              onClose={handleCloseSuggestedCandidateModal}
              suggestedCandidates={suggestedCandidates}
            />
          )}

          {isJobApplicantsModalOpen && jobApplicants !== null && (
            <JobApplicantsModal
              jobPostId={jobPostId}
              isOpen={isJobApplicantsModalOpen}
              onClose={handleCloseJobApplicantsModal}
              jobApplicants={jobApplicants}
            />
          )}

          {isMoreDetailsVisible && jobMoreDetails !== null && (
            <JobPostRecordModal
              isOpen={isMoreDetailsVisible}
              onClose={handleCloseJobMoreDetailsModal}
              jobPostData={jobMoreDetails}
            />
          )}

          {isSuggestedCandidateAndJobsModalOpen === true && (
            <SuggestedCandidatesAndJobsModal
              isOpen={isSuggestedCandidateAndJobsModalOpen}
              onClose={handleCloseCandidateAndJobsModalOpen}

            />
          )}
        </Grid>

        {isEditJobVisible && (
          <Grid size={{ xs: 12, md: 4 }} style={{ paddingTop: '16px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
            <Button variant='contained' startIcon={<ArrowBackIosNew />} onClick={handleCloseJobModal} style={{ marginBottom: '16px' }}>
              Back
            </Button>
            <JobPostForm
              jobPostQueryParams={queryParams}
              employerId={job.employerId}
              jobDetail={job}
              onClose={handleCloseJobModal}
              isOpen={isEditJobVisible}
            />
          </Grid>
        )}

        {isJobsFiltersOpen &&
          <Grid size={{ xs: 12, md: 3 }} style={{ paddingTop: '8px', paddingLeft: '16px', background: 'white', borderRadius: '5px 0', boxShadow: '-5px -5px 5px rgb(0 0 0 / 0.2)' }}>
            <JobPostFilters
              onClose={handleCloseJobsFilters}
              filtersData={jobsFilters}
            />
          </Grid>
        }

        {/* Bulk Action Confirmation Dialog */}
        {bulkActionConfirmDialog && (
          <ConfirmationDialog
            open={true}
            onClose={handleBulkActionConfirmDialogClose}
            onConfirm={handleBulkActionConfirmDialog}
            title={bulkActionConfirmTitle}
            message={bulkActionConfirmMsg}
          />
        )}

        {/* Delete Confirmation Dialog */}
        {showDeleteDialog === true && (
          <ConfirmationDialog
            open={true}
            onClose={handleConfirmationDialogClose}
            onConfirm={handleConfirmationDialog}
            message="Do you want to Delete?"
          />
        )}
      </Grid>
    </>
  );
};

export default ViewJobs;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}