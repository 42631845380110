// commonActions.js
import showToast from '../../utils/toast';
import { getNotificationsService, getNotificationUsersService } from '../service/commonService';
import { startLoader, stopLoader } from './loaderActions';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_NOTIFICATION_USERS = 'GET_NOTIFICATION_USERS';
export const GET_NOTIFICATION_USERS_SUCCESS = 'GET_NOTIFICATION_USERS_SUCCESS';
export const GET_NOTIFICATION_USERS_FAILURE = 'GET_NOTIFICATION_USERS_FAILURE';

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
});

export const getNotificationsSuccess = (response) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: response,
});

export const getNotificationsFailure = (error) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const getNotificationUsers = () => ({
  type: GET_NOTIFICATION_USERS,
});

export const getNotificationUsersSuccess = (response) => ({
  type: GET_NOTIFICATION_USERS_SUCCESS,
  payload: response,
});

export const getNotificationUsersFailure = (error) => ({
  type: GET_NOTIFICATION_USERS_FAILURE,
  payload: error,
});


export const getNotificationsList = (payload) => async (dispatch) => {
  dispatch(getNotifications());
  dispatch(startLoader());

  try {
    const response = await getNotificationsService(payload);

    if (response.success) {
      dispatch(getNotificationsSuccess(response));
    } else {
      dispatch(getNotificationsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getNotificationsFailure('Error getting notification list'));
    showToast('Error getting notification list', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getNotificationUsersList = (notificationId, payload) => async (dispatch) => {
  dispatch(getNotificationUsers());
  dispatch(startLoader());

  try {
    const response = await getNotificationUsersService(notificationId, payload);

    if (response.success) {
      dispatch(getNotificationUsersSuccess(response));
    } else {
      dispatch(getNotificationUsersFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getNotificationUsersFailure('Error getting notification user list'));
    showToast('Error getting notification user list', 'error');
  } finally {
    dispatch(stopLoader());
  }
};
