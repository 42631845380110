import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { formatIndianCurrency } from '../helpers/formatIndianCurrency';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import clipboardService from '../utils/clipboardService';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateWithTime, formatDDMMYYYYDate } from '../helpers/formattedDateTime';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
import { getSpecificJobsAuditList, resetSpecificJobsAudit } from '../redux/actions/jobActions';
import moment from 'moment';
import constants from '../helpers/constants.json';
import { ExpandLess, ExpandMore, Launch } from '@mui/icons-material';
import ShareLinkComponent from '../components/ShareLinkComponent';

const JobPostRecordModal = ({ isOpen, onClose, jobPostData }) => {

  const dispatch = useDispatch();
  const [isActivityLogsVisible, setIsActivityLogsVisible] = useState(false);
  const { revisionType } = constants;

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // maxWidth: '800px',
      width: '75%',
      maxHeight: '90%',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      padding: '20px',
      overflow: 'auto',
    },
  }

  const mainStyle = {
    divStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '4px'
    },
    spanStyle: {
      color: 'gray',
      textAlign: 'right',
      marginRight: '8px',
      minWidth: '130px',
      width: '130px',
      fontSize: '14px'
    }
  };

  const dummyImageURL = 'https://via.placeholder.com/150';

  const specificJobsAuditResponse = useSelector((state) => state.job.specificJobsAudit);
  const specificJobsAudit = specificJobsAuditResponse?.data;
  const auditLoader = useSelector((state) => state.loader.loading);

  useEffect(() => {
    return () => {
      dispatch(resetSpecificJobsAudit());
    };
  }, []);

  if (!jobPostData) {
    return null; // Don't render anything if jobPostData is null or undefined
  }

  const handleCopyClick = (shareLink) => {
    clipboardService.copyToClipboard(shareLink);
  };

  const handleExpandActivityLogs = () => {
    if (specificJobsAudit == null && !isActivityLogsVisible) {
      dispatch(getSpecificJobsAuditList(jobPostData?.jobPostId));
    }
    setIsActivityLogsVisible((prevState) => !prevState);
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Job Post Record Modal"
      style={customStyles}
    >

      <style>
        {`
          .ProfileTables table, .ProfileTables th, .ProfileTables td{
            border:1px solid #ddd;
            border-collapse: collapse;
            padding: 6px;
          }
        `}
      </style>

      {/* Information */}
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Job Post Details</h2>
          <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Header (Profile Pic and Important Info) */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img
              src={jobPostData.employerCompanyLogo || dummyImageURL}
              alt="Company Logo"
              style={{ width: '100px', height: '100px', borderRadius: '50%', border: '1px solid #333' }}
            />
          </div>
          <div style={{ marginLeft: '48px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <span style={{ color: 'gray', fontSize: '14px' }}>Job Title: </span>
                <span>{jobPostData.jobTitle}</span>
              </div>
              <div style={{ marginTop: '8px' }}>
                <ShareLinkComponent
                  entityId={jobPostData.jobPostId}
                  shareLinkType={constants.entityType.JOB_POST}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Job Information */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Job Information</h3>
          <Grid container>
            <Grid size={{ xs: 6 }}>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Job Title:</span>
                <span>{jobPostData.jobTitle || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Job Location:</span>
                <span>{jobPostData.jobLocation || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Posted by:</span>
                <span>{jobPostData.employerBrandName || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Company Name:</span>
                <span>{jobPostData.employerCompanyName || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Outlet Type:</span>
                <span>{jobPostData.employerCompanyType || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Salary Range:</span>
                <span>{jobPostData.salaryLowerRange ? formatIndianCurrency(jobPostData.salaryLowerRange) : '-'} -{' '}
                  {jobPostData.salaryUpperRange ? formatIndianCurrency(jobPostData.salaryUpperRange) : '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Additional Perks:</span>
                <span>{jobPostData.additionalPerks || '-'}</span>
              </div>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Shift Timing: </span>
                <span>{jobPostData.shiftStartTime || '-'} to {jobPostData.shiftEndTime || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Calling Hours:</span>
                <span>{jobPostData.callingHoursStart || '-'} to {jobPostData.callingHoursEnd || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Active: </span>
                <span>{jobPostData.active ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Job Expired:</span>
                <span>{jobPostData.jobExpired ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Accommodation Provided:</span>
                <span>{jobPostData.provideAccomodation ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>English Proficiency:</span>
                <span>{jobPostData.requiredEnglishProficiency || '-'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Zomato Link: </span>
                <span>
                  {jobPostData.employerZomatoLink ?
                    <Button size='small' color="primary" onClick={() => handleCopyClick(jobPostData.employerZomatoLink)}>
                      <ContentCopyIcon fontSize='small' />
                    </Button> : 'N/A'}
                </span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Other Link: </span>
                <span>
                  {jobPostData.employerOtherLink ?
                    <Button size='small' color="primary" onClick={() => handleCopyClick(jobPostData.employerOtherLink)}>
                      <ContentCopyIcon fontSize='small' />
                    </Button> : 'N/A'}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* Description */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Description:</h3>
          <div>{jobPostData.description || 'No description provided'}</div>
        </div>

        {/* JobPost Create and Update Dates */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Job Post Creation and Updating:</h3>
          <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
            <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
              <tr>
                <th><strong>Create Date</strong></th>
                <th><strong>Update Date</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{jobPostData.createDate ? formatDDMMYYYYDate(jobPostData.createDate) : "-"}</td>
                <td>{jobPostData.updateDate ? formatDDMMYYYYDate(jobPostData.updateDate) : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Activity Logs */}
        <div style={{ marginTop: '12px' }}>

          <h3 style={{ margin: 0, display: 'flex' }}>
            Activity Logs:
            <Button
              style={{ marginLeft: '8px' }}
              size='small'
              variant='outlined'
              disabled={auditLoader}
              endIcon={(isActivityLogsVisible && specificJobsAudit) ? <ExpandLess /> : <ExpandMore />}
              onClick={handleExpandActivityLogs}>
              {(isActivityLogsVisible && specificJobsAudit) ? "Hide" : "View"}
            </Button>
            {auditLoader && !specificJobsAudit &&
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}><CircularProgress size={24} /></div>
            }
          </h3>

          {isActivityLogsVisible && specificJobsAudit && specificJobsAudit.length > 0 &&
            <table className='ProfileTables' style={{ tableLayout: 'fixed', wordBreak: 'break-word', width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#d3d3d3', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th>Property Name</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {specificJobsAudit.map((item, index) => (
                  <>
                    <tr key={index} style={{ backgroundColor: '#f2f2f2' }}>
                      <td colSpan={3}>
                        <div style={{ flexDirection: 'row', display: 'flex', fontWeight: 'bold' }}>
                          <div style={{ fontSize: '16px', display: 'inline-block', marginRight: '8px' }}>{item.updatedBy}</div>
                          <div style={{ marginRight: '8px' }}>{revisionType[item.revisionType]}</div>
                          <div style={{ marginRight: '8px' }}>on</div>
                          <div style={{ display: 'inline-block' }}>{item.revisionDate && formatDateWithTime(item.revisionDate)}</div>
                        </div>
                      </td>
                    </tr>

                    {item.properties.length != 0 && item.properties.map((prop) => (
                      <>
                        <tr>
                          <td><div>{prop.propertyName}</div></td>
                          <td><div>{prop.oldValue}</div></td>
                          <td><div>{prop.newValue}</div></td>
                        </tr>
                      </>
                    ))}

                  </>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </ReactModal>
  );
};

export default JobPostRecordModal;
