import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import constants from '../helpers/constants.json';
import { exportCandidateListInExcel } from "../redux/actions/candidateActions";
import { exportEmployerListInExcel } from "../redux/actions/employerActions";
import { exportJobPostListInExcel } from "../redux/actions/jobActions";
import { useState } from "react";
import ConfirmationDialog from "./ConfirmationDialog";

const ExportComponent = ({ screenName, filters, totalRecords, style }) => {
    const dispatch = useDispatch();
    const [isConfirmDialog, setIsConfirmDialog] = useState(false);

    const handleExportClick = () => {
        setIsConfirmDialog(true);
    }

    const handleConfirmDialog = () => {
        if (screenName == constants.screenName.CANDIDATE) {
            dispatch(exportCandidateListInExcel(filters));
        } else if (screenName == constants.screenName.EMPLOYER) {
            dispatch(exportEmployerListInExcel(filters));
        } else if (screenName == constants.screenName.JOB_POST) {
            dispatch(exportJobPostListInExcel(filters));
        }
        setIsConfirmDialog(false);
    }

    const handleConfirmDialogClose = () => {
        setIsConfirmDialog(false);
    }

    const listName = {
        [constants.screenName.CANDIDATE]: "Candidates",
        [constants.screenName.EMPLOYER]: "Employers",
        [constants.screenName.JOB_POST]: "Jobs",
        [constants.screenName.GIG_POST]: "Gigs"
    }

    const title = "Export " + listName[screenName] + " Excel";
    const msg = "Are you sure, you want to export list with " + totalRecords + " records?";

    return (
        <div style={style}>
            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                <Button variant="contained" className="blackButton" onClick={handleExportClick}>
                    Export Excel
                </Button>
            </div>

            {/* Confirmation Dialog */}
            {isConfirmDialog && (
                <ConfirmationDialog
                    open={true}
                    onClose={handleConfirmDialogClose}
                    onConfirm={handleConfirmDialog}
                    title={title}
                    message={msg}
                />
            )}
        </div>
    )

}

export default ExportComponent;