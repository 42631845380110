import { Share } from "@mui/icons-material";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useState } from "react";
import { getShareLink } from "../redux/service/commonService";
import clipboardService from "../utils/clipboardService";

const ShareLinkComponent = ({ entityId, shareLinkType }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleCopyShareLink = async () => {
        setIsLoading(true);
        let payload = {
            "entityId": entityId,
            "shareLinkType": shareLinkType
        };
        let res = await getShareLink(payload);
        setIsLoading(false);

        let shareLink = res?.data;
        clipboardService.copyToClipboard(shareLink);
    }

    return (
        <Tooltip title="Share Link">
            <Button disabled={isLoading} style={{ minWidth: '32px' }} color="primary" onClick={handleCopyShareLink}>
                {isLoading ?
                    <CircularProgress size="24px" />
                    :
                    <Share />
                }
            </Button>
        </Tooltip>
    )

}

export default ShareLinkComponent;