import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../components/ButtonComponent';
import TextFieldComponent from '../components/TextFieldComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AsyncSelect from 'react-select/async';
import { getAppUsers, sendNotificationToUsers } from '../redux/service/userService';
import showToast from '../utils/toast';

const validationSchema = (isAllUsersSelected) => Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required'),
  userIds: isAllUsersSelected ? Yup.array() : Yup.array().min(1, "Select at least one user").required()
});

const SendNotificationModal = ({ isOpen, onClose, isAllUsersSelected, filters, excludedIds, users, usersCount }) => {
  const dispatch = useDispatch();

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      maxHeight: '90%',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      padding: '20px',
      overflow: 'auto',
    },
  };

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [initAppUsers, setInitAppUsers] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      body: '',
      userIds: users
    },
    validationSchema: validationSchema(isAllUsersSelected),

    onSubmit: async (values) => {

      const tempUserIds = values.userIds.map(item => item.id);
      let notData = {
        title: values.title,
        body: values.body
      }

      if (isAllUsersSelected) {
        notData['selectAll'] = true;
        notData['filters'] = filters;
        notData['excludedIds'] = excludedIds;
      } else {
        notData['users'] = tempUserIds;
      }

      try {
        await sendNotificationToUsers(notData);
        showToast("Notification sent successfully.", "success");
        formik.resetForm();
      } catch (err) {
        showToast("Something went wrong.", "error");
      }
    }
  });

  useEffect(() => {
    async function fetchData() {
      let queryParams = {
        "pageNumber": 0
      };
      let userListResponse = await getAppUsers(queryParams);
      setInitAppUsers(userListResponse.data);
    }
    fetchData();
  }, []);

  const filterUsers = async (inputValue) => {
    let queryParams = {
      "pageNumber": 0,
      "searchText": inputValue
    };
    let userListResponse = await getAppUsers(queryParams);
    return userListResponse.data;
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(typingTimeout); // Clear the previous timeout
      const timeout = setTimeout(() => {
        resolve(filterUsers(inputValue));
      }, 700);
      setTypingTimeout(timeout);
    });


  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Send Notification Modal"
      style={customStyles}
    >

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h2>Send Notification</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <form onSubmit={formik.handleSubmit}>
          <TextFieldComponent
            label="Title *"
            type="text"
            field={{
              ...formik.getFieldProps('title'),
              onChange: formik.handleChange('title'),
            }}
            form={formik}
            fullWidth
            style={{ marginBottom: '16px' }}
          />

          <TextFieldComponent
            label="Body *"
            type="text"
            multiline={true}
            rows={5}
            field={{
              ...formik.getFieldProps('body'),
              onChange: formik.handleChange('body'),
            }}
            form={formik}
            fullWidth
            style={{ marginBottom: '16px' }}
          />

          <div style={{ marginBottom: 16 }}>
            {isAllUsersSelected ?
              <div style={{ marginTop: '8px', fontWeight: 'bold' }}>[<span style={{ color: '#1976d2' }}>{usersCount}</span> Users Selected]</div>
              :
              <>
                <AsyncSelect
                  name="userIds"
                  onChange={(e) => {
                    console.log(e);
                    formik.setFieldValue('userIds', e);
                  }}
                  form={formik}
                  fullWidth
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  placeholder="Select Users"
                  defaultOptions={initAppUsers}
                  loadOptions={promiseOptions}
                  getOptionLabel={(option) => option?.name?.toString() + ` [${option?.mobileNumber?.toString()}]` || ""}
                  getOptionValue={(option) => option?.id?.toString() || ""}
                  value={formik.values.userIds}
                />
                {formik && formik?.touched["userIds"] && formik?.errors["userIds"] &&
                  <FormHelperText>{formik?.errors["userIds"]}</FormHelperText>
                }
                <div style={{ marginTop: '8px', fontWeight: 'bold' }}>[<span style={{ color: '#1976d2' }}>{formik.values.userIds.length}</span> Users Selected]</div>
              </>
            }
          </div>

          <ButtonComponent
            type="submit"
            label={formik.isSubmitting ? 'Sending...' : 'Send Notification'}
            disabled={formik.isSubmitting}
            fullWidth
          />
        </form>

      </div>


    </ReactModal>
  );
};

export default SendNotificationModal;
