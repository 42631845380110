// NotificationDetailModal.js
import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Close as CloseIcon, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination } from '@mui/material';
import { getNotificationUsersList } from '../redux/actions/commonActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PaginationComponent from '../components/PaginationComponent';

const NotificationDetailModal = ({ isOpen, onClose, notificationDetail }) => {

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    },
  }

  const dispatch = useDispatch();

  const notificationUsers = useSelector((state) => state.common.notificationUsers);

  const totalRecords = notificationUsers?.metadata?.totalRecords;
  const totalPages = notificationUsers?.metadata?.totalPages;

  const [searchTerm, setSearchTerm] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  console.log(notificationDetail);

  const handleCloseNotificationDetailModal = () => {
    onClose();
  };

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0, // default to the first page
    searchText: ''
  });

  const handlePageChange = (event, page) => {
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
  };

  useEffect(() => {
    let notificationId = notificationDetail?.id;
    dispatch(getNotificationUsersList(notificationId, queryParams));
  }, [dispatch, queryParams])

  const handleSearch = (text) => {
    setSearchTerm(text);
    clearTimeout(typingTimeout); // Clear the previous timeout
    const timeout = setTimeout(() => {
      setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
    }, 700);
    setTypingTimeout(timeout);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Notification Detail Modal"
      style={customStyles}
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Notification Detail And Users {totalRecords ? `(${totalRecords})` : ""}</h2>
          <IconButton onClick={handleCloseNotificationDetailModal} style={{ marginRight: '-12px', marginTop: '-12px' }}>
            <CloseIcon />
          </IconButton>
        </div>

        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ minWidth: '80px', fontWeight: 'bold' }}>
              Title:
            </div>
            <div>
              {notificationDetail?.title}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ minWidth: '80px', fontWeight: 'bold' }}>
              Body:
            </div>
            <div>
              {notificationDetail?.body}
            </div>
          </div>
        </div>

        <TableContainer component={Paper} style={{ marginTop: '12px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Name</TableCell>
                <TableCell style={styles.textBold}>Mobile Number</TableCell>
                <TableCell style={styles.textBold}>User Type</TableCell>
                <TableCell style={styles.textBold}>Device Registered?</TableCell>
                <TableCell style={styles.textBold}>Status</TableCell>
                <TableCell style={styles.textBold}>Created At</TableCell>
                <TableCell style={styles.textBold}>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationUsers?.data?.map(user => (
                <TableRow key={user.userId}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.mobileNumber}</TableCell>
                  <TableCell>{user.candidate ? 'Candidate' : 'Employer'}</TableCell>
                  <TableCell>{user.hasDeviceToken ? "Yes" : "No"}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>{user.createDate && moment(user.createDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{user.updateDate && moment(user.updateDate).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <PaginationComponent
            pageNumber={queryParams.pageNumber + 1}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>

      </div>
    </ReactModal>
  );
};

export default NotificationDetailModal;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}