import React, { useState } from 'react';
import ReactModal from 'react-modal';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector, } from 'react-redux';
import CandidateProfileModal from './CandidateProfile';
import { getSingleCandidateData } from '../redux/actions/candidateActions';
import { getJobApplicantsList, setJobApplicantsPageNumber } from '../redux/actions/jobActions';
import { convertToShortYearAndMonth } from '../helpers/utility';
import PaginationComponent from '../components/PaginationComponent';

const JobApplicantsModal = ({ isOpen, onClose, jobApplicants, jobPostId }) => {
  const dispatch = useDispatch();

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    },
  }

  const currentCandidates = jobApplicants?.data || [];

  const totalRecords = jobApplicants?.metadata?.totalRecords;
  const totalPages = jobApplicants?.metadata?.totalPages;

  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const candidateById = useSelector((state) => state.candidate.candidateById);
  const candidateDataById = candidateById?.data;

  const jobApplicantsPageNumber = useSelector((state) => state.job.jobApplicantsPageNumber);

  const handlePageChange = (event, page) => {
    dispatch(setJobApplicantsPageNumber(page - 1));
    dispatch(getJobApplicantsList(jobPostId, (page - 1)));
  };

  if (!jobApplicants) {
    return null; // Don't render anything if jobApplicants is null or undefined
  }

  const handleViewMoreClick = (id) => {
    handleCloseCandidateModal();
    dispatch(getSingleCandidateData(id));
    setIsCandidateModalOpen(true)
  }

  const handleCloseCandidateModal = () => {
    setIsCandidateModalOpen(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Job Applicants Modal"
      style={customStyles}
    >
      <div style={{ width: '100%' }}>
        {/* Candidate Information */}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Job Applicants {totalRecords ? `(${totalRecords})` : ""}</h2>
          <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>


        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.textBold}>Candidate Name</TableCell>
                <TableCell style={styles.textBold}>Location</TableCell>
                <TableCell style={styles.textBold}>Job Location</TableCell>
                <TableCell style={styles.textBold}>Last Job Title</TableCell>
                <TableCell style={styles.textBold}>Last Job Company</TableCell>
                <TableCell style={styles.textBold}>Experience</TableCell>
                <TableCell style={styles.textBold}>Highest Education</TableCell>
                <TableCell style={styles.textBold}>Last Course Name</TableCell>
                <TableCell style={styles.textBold}>Applied On Date</TableCell>
                <TableCell style={styles.textBold}>Application Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCandidates?.map((applicant, index) => (
                <TableRow
                  onClick={() => setSelectedRow(index)}
                  onDoubleClick={() => handleViewMoreClick(applicant.candidateId)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: selectedRow === index ? '#e0e0e0' : 'white',
                  }}
                  key={applicant.candidateId}>
                  <TableCell>{applicant.candidateName}</TableCell>
                  <TableCell>{applicant.location}</TableCell>
                  <TableCell>{applicant.jobLocation}</TableCell>
                  <TableCell>{applicant.lastJobTitle || 'Not specified'}</TableCell>
                  <TableCell>{applicant.lastJobCompany || 'Not specified'}</TableCell>
                  <TableCell>{convertToShortYearAndMonth(applicant.monthsOfExperience) || 'No experience'}</TableCell>
                  <TableCell>{applicant.highestEducation}</TableCell>
                  <TableCell>{applicant.lastCourseName || 'Not specified'}</TableCell>
                  <TableCell>{moment(applicant.appliedOnDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{applicant.applicationStatus || 'Not specified'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <PaginationComponent
            pageNumber={jobApplicantsPageNumber + 1}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      {isCandidateModalOpen && candidateDataById !== null && (
        <CandidateProfileModal
          isOpen={isCandidateModalOpen}
          onClose={handleCloseCandidateModal}
          candidateData={candidateDataById}
        />
      )}


    </ReactModal>
  );
};

export default JobApplicantsModal;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}