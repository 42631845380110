import React from 'react'

function HeaderComponent({ name, totalRecords }) {

    const customStyles = {
        header: {
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            marginBottom: 20
        },
        text: {
            display: 'flex',
            fontSize: '20px',
            padding: '10px',
            fontWeight: 'bold'
        }
    }

    return (
        <div style={customStyles.header}>
            <span style={customStyles.text}>
                {name} {totalRecords ? `(${totalRecords})` : ""}
            </span>
        </div>
    )
}

export default HeaderComponent