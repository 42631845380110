// AuditDetailModal.js
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector, } from 'react-redux';
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getSpecificEmployersAuditRevisionList, resetSpecificEmployersAudit } from '../redux/actions/employerActions';
import constants from '../helpers/constants.json';
import { formatDateWithTime } from '../helpers/formattedDateTime';

const AuditDetailModal = ({ isOpen, onClose, auditRecord }) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(4px)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      left: '5%',
      top: '10%',
      zIndex: 1001,
      width: '94%',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      overflowY: 'auto',
    },
  }
  const dispatch = useDispatch();
  const { revisionType } = constants;

  // TODO: as a temporary workaround used employer specific state for all types of audit details
  const specificAuditResponse = useSelector((state) => state.employer.specificEmployersAudit);
  const specificAudit = specificAuditResponse?.data;

  useEffect(() => {
    // TODO: as a temporary workaround used employer specific state for all types of audit details
    dispatch(getSpecificEmployersAuditRevisionList(auditRecord));
    return () => {
      dispatch(resetSpecificEmployersAudit());
    };
  }, [auditRecord]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Audit Detail Modal"
      style={customStyles}
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Activity Logs Detail</h2>
          <IconButton onClick={onClose} style={{ marginTop: '1rem' }}>
            <CloseIcon />
          </IconButton>
        </div>

        <div>

          <style>{`
            .ActivityLogs table, .ActivityLogs th, .ActivityLogs td{
              border:1px solid black;
              border-collapse: collapse;
              padding: 4px;
            }
          `}</style>
          {specificAudit && specificAudit.length > 0 &&
            <table className='ActivityLogs' style={{ tableLayout: 'fixed', wordBreak: 'break-word', width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#d3d3d3', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th>Property Name</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {specificAudit.map((item) => (
                  <>
                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                      <td colSpan={3}>
                        <div style={{ flexDirection: 'row', display: 'flex', fontWeight: 'bold' }}>
                          <div style={{ fontSize: '16px', display: 'inline-block', marginRight: '8px' }}>{item.updatedBy}</div>
                          <div style={{ marginRight: '8px' }}>{revisionType[item.revisionType]}</div>
                          <div style={{ marginRight: '8px' }}>on</div>
                          <div style={{ display: 'inline-block' }}>{item.revisionDate && formatDateWithTime(item.revisionDate)}</div>
                        </div>
                      </td>
                    </tr>

                    {item.properties.length != 0 && item.properties.map((prop) => (
                      <>
                        <tr>
                          <td><div>{prop.propertyName}</div></td>
                          <td><div>{prop.oldValue}</div></td>
                          <td><div>{prop.newValue}</div></td>
                        </tr>
                      </>
                    ))}

                    {item.entityObject && Object.keys(item.entityObject).map((keys) => (
                      <>
                        <tr>
                          <td><div>{keys}</div></td>
                          <td>{item.revisionType == "DEL" && <div>{item.entityObject[keys]}</div>}</td>
                          <td>{item.revisionType == "ADD" && <div>{item.entityObject[keys]}</div>}</td>
                        </tr>
                      </>
                    ))}

                  </>
                ))}
              </tbody>
            </table>
          }
        </div>


      </div>


    </ReactModal>


  );
};

export default AuditDetailModal;