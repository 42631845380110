import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextFieldComponent from '../components/TextFieldComponent';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComponent from '../components/HeaderComponent';
import PaginationComponent from '../components/PaginationComponent';
import { getNotificationsList } from '../redux/actions/commonActions';
import NotificationDetailModal from '../models/NotificationDetailModal';
import { Button, Tooltip } from '@mui/material';
import SendNotificationModal from '../models/SendNotificationModal';
import { Add } from '@mui/icons-material';
import { formatDateWithTime } from '../helpers/formattedDateTime';

const ViewNotifications = () => {

  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.common.notifications);

  const totalRecords = notifications?.metadata?.totalRecords;
  const totalPages = notifications?.metadata?.totalPages;

  const [searchTerm, setSearchTerm] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isNotificationDetailVisible, setIsNotificationDetailVisible] = useState(false);
  const [notificationDetail, setNotificationDetail] = useState(null);
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);

  const [queryParams, setQueryParams] = useState({
    pageNumber: 0, // default to the first page
    searchText: ''
  });

  const handlePageChange = (event, page) => {
    setQueryParams((prevParams) => ({ ...prevParams, pageNumber: page - 1 }));
  };

  useEffect(() => {
    dispatch(getNotificationsList(queryParams));
  }, [dispatch, queryParams])

  const handleSearch = (text) => {
    setSearchTerm(text);
    clearTimeout(typingTimeout); // Clear the previous timeout
    const timeout = setTimeout(() => {
      setQueryParams((prevParams) => ({ ...prevParams, searchText: text, pageNumber: 0 }));
    }, 700);
    setTypingTimeout(timeout);
  };

  const handleViewMoreClick = (notification) => {
    setIsNotificationDetailVisible(true);
    setNotificationDetail(notification);
  }

  const handleCloseNotificationDetail = () => {
    setIsNotificationDetailVisible(false);
  }

  const handleSendNotification = () => {
    setIsSendNotificationModalOpen(true);
  }

  const handleCloseSendNotificationModal = () => {
    setIsSendNotificationModalOpen(false);
  }


  return (
    <>
      <HeaderComponent name="Notifications" totalRecords={totalRecords} />
      <Grid container spacing={3}>
        {/* Left side: Admins Table */}
        <Grid size={{ xs: 12, md: 12 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Search Input on the Left Side */}
            <TextFieldComponent
              label="Search"
              type="search"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '350px' }}
            />

            {/* Send Notification Button on the Right Side */}
            <Tooltip title="Send Notification">
              <Button style={{ whiteSpace: 'nowrap' }} variant="contained" color="primary" onClick={handleSendNotification}>
                <Add style={{ marginRight: '8px' }} />
                Send Notification
              </Button>
            </Tooltip>
          </div>

          <div style={{ margin: '16px 0' }} />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.textBold}>Title</TableCell>
                  <TableCell style={styles.textBold}>Body</TableCell>
                  <TableCell style={styles.textBold}>Type</TableCell>
                  <TableCell style={styles.textBold}>Sent By</TableCell>
                  <TableCell style={styles.textBold}>Target Users</TableCell>
                  <TableCell style={styles.textBold}>Sent</TableCell>
                  <TableCell style={styles.textBold}>Failed</TableCell>
                  <TableCell style={styles.textBold}>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications?.data?.map((notification, index) => (
                  <TableRow key={index}
                    onDoubleClick={() => handleViewMoreClick(notification)}
                  >
                    <TableCell>{notification?.title}</TableCell>
                    <TableCell>{notification?.body}</TableCell>
                    <TableCell>{notification?.type}</TableCell>
                    <TableCell>{notification?.adminUserName}</TableCell>
                    <TableCell>{notification?.targetUserCount}</TableCell>
                    <TableCell>{notification?.successCount}</TableCell>
                    <TableCell>{notification?.failedCount}</TableCell>
                    <TableCell>{notification.createDate && formatDateWithTime(notification.createDate)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
            <PaginationComponent
              pageNumber={queryParams.pageNumber + 1}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Grid>
        </Grid>

        {isNotificationDetailVisible &&
          <NotificationDetailModal
            isOpen={isNotificationDetailVisible}
            onClose={handleCloseNotificationDetail}
            notificationDetail={notificationDetail}
          />
        }

        {isSendNotificationModalOpen &&
          <SendNotificationModal
            isOpen={isSendNotificationModalOpen}
            onClose={handleCloseSendNotificationModal}
            isAllUsersSelected={false}
            users={[]}
          />
        }

      </Grid>
    </>
  );
};

export default ViewNotifications;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}