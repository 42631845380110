// commonReducer.js
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_FAILURE, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATION_USERS, GET_NOTIFICATION_USERS_SUCCESS, GET_NOTIFICATION_USERS_FAILURE } from '../actions/commonActions';

const initialState = {
  notifications: [],
  notificationUsers: []
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };

    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
      };

    case GET_NOTIFICATION_USERS:
      return {
        ...state,
      };

    case GET_NOTIFICATION_USERS_SUCCESS:
      return {
        ...state,
        notificationUsers: action.payload,
      };

    case GET_NOTIFICATION_USERS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default commonReducer;
