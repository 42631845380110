import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { referralCodeDetail, referralCodeList } from '../redux/selectors/userSelector';
import { getReferralCodeList, getReferralUserDetail } from '../redux/actions/userActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReferralDetailModal from '../models/ReferralsDetails';
import HeaderComponent from '../components/HeaderComponent';

const ViewReferrals = () => {
  const dispatch = useDispatch();

  const [isReferralDetailModalOpen, setIsReferralDetailModalOpen] = useState('')
  const referrals = useSelector(referralCodeList);
  const referralDetails = useSelector(referralCodeDetail);

  useEffect(() => {
    dispatch(getReferralCodeList());
  }, [dispatch]);

  const openReferralDetail = (referral) => {
    setIsReferralDetailModalOpen(true);
    dispatch(getReferralUserDetail(referral.referralCode))
  }

  const handleCloseReferralDetailModal = () => {
    setIsReferralDetailModalOpen(false);
  }

  return (
    <>
      <HeaderComponent name="Referrals" />
      <Grid container spacing={3}>
        <Grid size={{ xs: 12 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.textBold}>Name</TableCell>
                  <TableCell style={styles.textBold}>Referral Code</TableCell>
                  <TableCell style={styles.textBold}>Referral Count</TableCell>
                  <TableCell style={styles.textBold}>View More</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referrals?.map((referral) => (
                  <TableRow
                    onDoubleClick={() => openReferralDetail(referral)}
                    key={referral.referralCode}>
                    <TableCell>{referral.name}</TableCell>
                    <TableCell>{referral.referralCode}</TableCell>
                    <TableCell>{referral.referralCount}</TableCell>
                    <TableCell style={{ cursor: 'pointer' }} onClick={() => openReferralDetail(referral)}>  <VisibilityIcon /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isReferralDetailModalOpen && referralDetails !== null && (
            <ReferralDetailModal
              isOpen={isReferralDetailModalOpen}
              onClose={handleCloseReferralDetailModal}
              referralDetails={referralDetails}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ViewReferrals;

const styles = {
  textBold: {
    fontWeight: 'bold'
  }
}