import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import moment from 'moment';
import { Button, CircularProgress, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDateWithTime, formatDDMMYYYYDate } from '../helpers/formattedDateTime';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDocument } from '../redux/actions/userActions';
import ConfirmationDialog from '../components/ConfirmationDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import openLink from '../redux/service/openLinkService';
import { getSpecificEmployersAuditList, resetSpecificEmployersAudit } from '../redux/actions/employerActions';
import constants from '../helpers/constants.json';
import clipboardService from '../utils/clipboardService';
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import AddRemarksModal from './AddRemarksModal';

const EmployerProfileModal = ({ isOpen, onClose, employerData }) => {
  const dummyImageURL = 'https://via.placeholder.com/150';
  const dispatch = useDispatch();
  const [isEmployerDocumentDeleteModalOpen, setIsEmployerDocumentDeleteModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [isActivityLogsVisible, setIsActivityLogsVisible] = useState(false);
  const [isRemarksHistoryVisible, setIsRemarksHistoryVisible] = useState(false);
  const [isAddRemarksModalVisible, setIsAddRemarksModalVisible] = useState(false);
  const { revisionType } = constants;

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      marginTop: '2em'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // maxWidth: '800px',
      width: '75%',
      maxHeight: '90%',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      padding: '20px',
      overflow: 'auto',
    },
  };

  const mainStyle = {
    divStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '4px'
    },
    spanStyle: {
      color: 'gray',
      textAlign: 'right',
      marginRight: '8px',
      minWidth: '130px',
      width: '130px',
      fontSize: '14px'
    }
  };

  const specificEmployersAuditResponse = useSelector((state) => state.employer.specificEmployersAudit);
  const specificEmployersAudit = specificEmployersAuditResponse?.data;
  const auditLoader = useSelector((state) => state.loader.loading);

  const rmList = employerData?.remarksList;
  // get most recent remark; assuming remarksList is sorted by create date in descending order
  const currentRemarkObj = (rmList && rmList?.length != 0) ? rmList[0] : null;

  useEffect(() => {
    return () => {
      dispatch(resetSpecificEmployersAudit());
    };
  }, []);

  if (!employerData) {
    return null; // Don't render anything if employerData is null or undefined
  }

  const handleLinkClick = (link) => {
    openLink(link)
  };

  const handleCopyClick = (shareLink) => {
    clipboardService.copyToClipboard(shareLink);
  };

  const deleteEmployerDocument = (id) => {
    setDocumentId(id);
    setIsEmployerDocumentDeleteModalOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setIsEmployerDocumentDeleteModalOpen(false);
  };

  const handleConfirmationDialog = () => {
    dispatch(deleteDocument("employer", documentId));
    setIsEmployerDocumentDeleteModalOpen(false);
  };

  const handleExpandActivityLogs = () => {
    if (specificEmployersAudit == null && !isActivityLogsVisible) {
      dispatch(getSpecificEmployersAuditList(employerData?.employerId));
    }
    setIsActivityLogsVisible((prevState) => !prevState);
  }

  const handleExpandRemarksHistory = () => {
    setIsRemarksHistoryVisible((prevState) => !prevState);
  }

  const handleAddRemarks = () => {
    setIsAddRemarksModalVisible(true);
  }

  const handleCloseAddRemarksModal = () => {
    setIsAddRemarksModalVisible(false);
  }

  const planStatusString = (employer) => {
    let firstStr = (employer?.planExpired) ? "Expired" : "Active";
    let secondStr = (employer?.planExceeded) ? " and Limit Exceeded" : "";
    return firstStr + secondStr;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Employer Profile Modal"
      style={customStyles}
    >

      <style>
        {`
          .ProfileTables table, .ProfileTables th, .ProfileTables td{
            border:1px solid #ddd;
            border-collapse: collapse;
            padding: 6px;
          }
        `}
      </style>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h2>Employer Profile</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div style={{ textAlign: 'left', color: '#333' }}>

        {/* Header (Profile Pic and Important Info) */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img
              src={employerData?.profilePic || dummyImageURL}
              alt="Profile"
              style={{ width: '100px', height: '100px', borderRadius: '50%', border: '1px solid #333' }}
            />
          </div>
          <div style={{ marginLeft: '48px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <span style={{ color: 'gray', fontSize: '14px' }}>Mobile Number: </span>
                <span>{employerData.mobileNumber}</span>
                <IconButton style={{ marginLeft: '4px' }} color='primary' onClick={() => handleCopyClick(employerData.mobileNumber)}>
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </div>
              <div>
                <span style={{ color: 'gray', fontSize: '14px' }}>Brand Name: </span>
                <span>{employerData.brandName}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Employer Information */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Personal & Company Information</h3>
          <Grid container>
            <Grid size={{ xs: 6 }}>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Name:</span>
                <span>{employerData.name}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Mobile Number:</span>
                <span>{employerData.mobileNumber}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Gender:</span>
                <span>{employerData.gender}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>DOB:</span>
                <span>{employerData && employerData.dob ? (formatDDMMYYYYDate(employerData.dob)) : "-"}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Referral Code:</span>
                <span>{employerData.referralCode}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Profile Incomplete: </span>
                <span>{employerData.profileInComplete ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Address: </span>
                <span>{[employerData.addressLine1, employerData.addressLine2, employerData.addressLine3].filter(Boolean).join(', ')}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Job Openings: </span>
                <span>{employerData.jobOpenings}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Past Hires: </span>
                <span>{employerData.pastHires}</span>
              </div>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Brand Name: </span>
                <span>{employerData.brandName}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Company Name:</span>
                <span>{employerData.companyName}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Outlet Type: </span>
                <span>{employerData.companyType}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Company Email:</span>
                <span>{employerData.companyEmail}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Current Remarks:</span>
                <span>{currentRemarkObj != null ? currentRemarkObj.remarks : 'No remarks'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Active: </span>
                <span>{employerData.active ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Verified: </span>
                <span>{employerData.verified ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Assisted Hire: </span>
                <span>{employerData.assistedHire ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Gig Approved: </span>
                <span>{employerData.gigApproved ? 'Yes' : 'No'}</span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Zomato Link: </span>
                <span>
                  {employerData.zomatoLink ?
                    <Button size='small' color="primary" onClick={() => handleCopyClick(employerData.zomatoLink)}>
                      <ContentCopyIcon fontSize='small' />
                    </Button> : 'N/A'}
                </span>
              </div>
              <div style={mainStyle.divStyle}>
                <span style={mainStyle.spanStyle}>Other Link: </span>
                <span>
                  {employerData.otherLink ?
                    <Button size='small' color="primary" onClick={() => handleCopyClick(employerData.otherLink)}>
                      <ContentCopyIcon fontSize='small' />
                    </Button> : 'N/A'}
                </span>
              </div>
            </Grid>
          </Grid>
        </div >

        {/* Subscription Plan */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Subscription Plan: [{planStatusString(employerData)}]</h3>
          {
            employerData?.subPlanDetails?.length > 0 ? (
              <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
                <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
                  <tr>
                    <th><strong>Plan Name</strong></th>
                    <th><strong>Start Date</strong></th>
                    <th><strong>End Date</strong></th>
                    {/* <th><strong>Purchase Date</strong></th> */}
                  </tr>
                </thead>
                <tbody>
                  {employerData.subPlanDetails.map((subPlan) => (
                    <tr key={subPlan.id}>
                      <td>{subPlan.planName}</td>
                      <td>{moment(subPlan.startDate).format('DD/MM/YYYY')}</td>
                      <td>{moment(subPlan.endDate).format('DD/MM/YYYY')}</td>
                      {/* <td>{moment(subPlan.purchaseDate).format('DD/MM/YYYY')}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No Subscription Plan</div>
            )
          }
        </div >

        {/* Documents */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Documents</h3>
          {employerData.documents.length > 0 ? (
            <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th>Document Name</th>
                  <th>View Document</th>
                  <th>Create Date</th>
                  <th>Update Date</th>
                  <th>Delete Document</th>
                </tr>
              </thead>
              <tbody>
                {employerData.documents.map((doc) => (
                  <tr key={doc.id}>
                    <td>{doc.type}</td>
                    <td>
                      <a href={doc.url} target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    </td>
                    <td>{doc.createDate ? moment(doc.createDate).format("DD/MM/YYYY") : '-'}</td>
                    <td>{doc.updateDate ? moment(doc.updateDate).format("DD/MM/YYYY") : '-'}</td>
                    <td>
                      <IconButton onClick={() => deleteEmployerDocument(doc.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No Documents</div>
          )}
        </div>

        {/* Profile Create and Update Dates */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Profile Creation and Updating:</h3>
          <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
            <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
              <tr>
                <th><strong>Create Date</strong></th>
                <th><strong>Update Date</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{employerData.createDate ? formatDDMMYYYYDate(employerData.createDate) : "-"}</td>
                <td>{employerData.updateDate ? formatDDMMYYYYDate(employerData.updateDate) : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Remarks */}
        <div style={{ marginTop: '12px' }}>
          <h3 style={{ margin: 0 }}>Remarks History:
            <Button
              style={{ marginLeft: '8px' }}
              size='small'
              variant='outlined'
              disabled={employerData?.remarksList?.length == 0}
              endIcon={(isRemarksHistoryVisible) ? <ExpandLess /> : <ExpandMore />}
              onClick={handleExpandRemarksHistory}>
              {(isRemarksHistoryVisible) ? "Hide" : "View"}
            </Button>

            <Button
              style={{ marginLeft: '32px' }}
              size='small'
              variant='outlined'
              endIcon={<Add />}
              onClick={handleAddRemarks}
            >
              Add Remarks
            </Button>
          </h3>
          {isRemarksHistoryVisible && employerData?.remarksList?.length > 0 && (
            <table className='ProfileTables' style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th><strong>Remarks</strong></th>
                  <th><strong>Added By</strong></th>
                  <th><strong>Create Date</strong></th>
                  <th><strong>Update Date</strong></th>
                </tr>
              </thead>
              <tbody>
                {employerData.remarksList.map((rm) => (
                  <tr key={rm.id}>
                    <td>{rm.remarks}</td>
                    <td>{rm.adminUserName}</td>
                    <td>{rm.createDate && moment(rm.createDate).format('DD/MM/YYYY')}</td>
                    <td>{rm.updateDate && moment(rm.updateDate).format('DD/MM/YYYY')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {employerData?.remarksList?.length == 0 && <div>No Remarks</div>}

        </div>

        {/* Activity Logs */}
        <div style={{ marginTop: '12px' }}>

          <h3 style={{ margin: 0, display: 'flex' }}>
            Activity Logs:
            <Button
              style={{ marginLeft: '8px' }}
              size='small'
              variant='outlined'
              disabled={auditLoader}
              endIcon={(isActivityLogsVisible && specificEmployersAudit) ? <ExpandLess /> : <ExpandMore />}
              onClick={handleExpandActivityLogs}>
              {(isActivityLogsVisible && specificEmployersAudit) ? "Hide" : "View"}
            </Button>
            {auditLoader && !specificEmployersAudit &&
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}><CircularProgress size={24} /></div>
            }
          </h3>

          {isActivityLogsVisible && specificEmployersAudit && specificEmployersAudit.length > 0 &&
            <table className='ProfileTables' style={{ tableLayout: 'fixed', wordBreak: 'break-word', width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              <thead style={{ backgroundColor: '#d3d3d3', borderBottom: '1px solid #ddd' }}>
                <tr>
                  <th>Property Name</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {specificEmployersAudit.map((item, index) => (
                  <>
                    <tr key={index} style={{ backgroundColor: '#f2f2f2' }}>
                      <td colSpan={3}>
                        <div style={{ flexDirection: 'row', display: 'flex', fontWeight: 'bold' }}>
                          <div style={{ fontSize: '16px', display: 'inline-block', marginRight: '8px' }}>{item.updatedBy}</div>
                          <div style={{ marginRight: '8px' }}>{revisionType[item.revisionType]}</div>
                          <div style={{ marginRight: '8px' }}>on</div>
                          <div style={{ display: 'inline-block' }}>{item.revisionDate && formatDateWithTime(item.revisionDate)}</div>
                        </div>
                      </td>
                    </tr>

                    {item.properties.length != 0 && item.properties.map((prop) => (
                      <>
                        <tr>
                          <td><div>{prop.propertyName}</div></td>
                          <td><div>{prop.oldValue}</div></td>
                          <td><div>{prop.newValue}</div></td>
                        </tr>
                      </>
                    ))}

                  </>
                ))}
              </tbody>
            </table>
          }

        </div>
      </div >

      {/* Delete Confirmation Dialog */}
      {
        isEmployerDocumentDeleteModalOpen && (
          <ConfirmationDialog
            open={true}
            onClose={handleConfirmationDialogClose}
            onConfirm={handleConfirmationDialog}
            message="Do you want to Delete?"
          />
        )
      }

      {isAddRemarksModalVisible &&
        <AddRemarksModal
          open={true}
          onClose={handleCloseAddRemarksModal}
          isCandidate={false}
          userId={employerData?.employerId}
        />
      }

    </ReactModal >
  );
};

export default EmployerProfileModal;
