import showToast from '../../utils/toast';
import { getJobsService, getSuggestedCandidatesService, getJobApplicantsService, getJobPostByIdService, createJobService, jobListsService, editJobService, deleteJobService, deleteJobRoleService, patchJobRoleService, createJobRoleService, onlyJobRolesService, getJobsAuditService, getSpecificJobsAuditService, exportJobPostListService, bulkUpdateJobService } from '../service/jobService';
import { startLoader, stopLoader } from './loaderActions';

export const ADD_JOB = 'ADD_JOB';
export const ADD_JOB_SUCCESS = 'ADD_JOB_SUCCESS';
export const ADD_JOB_FAILURE = 'ADD_JOB_FAILURE';

export const EDIT_JOB = 'EDIT_JOB';
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';
export const EDIT_JOB_FAILURE = 'EDIT_JOB_FAILURE';

export const DELETE_JOB = 'DELETE_JOB';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILURE = 'DELETE_JOB_FAILURE';

export const BULK_UPDATE_JOB = 'BULK_UPDATE_JOB';
export const BULK_UPDATE_JOB_SUCCESS = 'BULK_UPDATE_JOB_SUCCESS';
export const BULK_UPDATE_JOB_FAILURE = 'BULK_UPDATE_JOB_FAILURE';

export const JOB_ROLES = 'JOB_ROLES';
export const JOB_ROLES_SUCCESS = 'JOB_ROLES_SUCCESS';
export const JOB_ROLES_FAILURE = 'JOB_ROLES_FAILURE';

export const GET_JOBS = 'GET_JOBS';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_FAILURE = 'GET_JOBS_FAILURE';

export const SET_JOBS_FILTERS = 'SET_JOBS_FILTERS';

export const GET_JOBS_AUDIT = 'GET_JOBS_AUDIT';
export const GET_JOBS_AUDIT_SUCCESS = 'GET_JOBS_AUDIT_SUCCESS';
export const GET_JOBS_AUDIT_FAILURE = 'GET_JOBS_AUDIT_FAILURE';

export const GET_SPECIFIC_JOBS_AUDIT = 'GET_SPECIFIC_JOBS_AUDIT';
export const GET_SPECIFIC_JOBS_AUDIT_SUCCESS = 'GET_SPECIFIC_JOBS_AUDIT_SUCCESS';
export const GET_SPECIFIC_JOBS_AUDIT_FAILURE = 'GET_SPECIFIC_JOBS_AUDIT_FAILURE';
export const RESET_SPECIFIC_JOBS_AUDIT = 'RESET_SPECIFIC_JOBS_AUDIT';

export const GET_SINGLE_JOB_BY_ID = 'GET_SINGLE_JOB_BY_ID';
export const GET_SINGLE_JOB_BY_ID_SUCCESS = 'GET_SINGLE_JOB_BY_ID_SUCCESS';
export const GET_SINGLE_JOB_BY_ID_FAILURE = 'GET_SINGLE_JOB_BY_ID_FAILURE';

export const GET_JOB_APPLICANTS = 'GET_JOB_APPLICANTS';
export const GET_JOB_APPLICANTS_SUCCESS = 'GET_JOB_APPLICANTS_SUCCESS';
export const GET_JOB_APPLICANTS_FAILURE = 'GET_JOB_APPLICANTS_FAILURE';

export const GET_SUGGESTED_CANDIDATES = 'GET_SUGGESTED_CANDIDATES';
export const GET_SUGGESTED_CANDIDATES_SUCCESS = 'GET_SUGGESTED_CANDIDATES_SUCCESS';
export const GET_SUGGESTED_CANDIDATES_FAILURE = 'GET_SUGGESTED_CANDIDATES_FAILURE';

export const RESET_ADDED_JOB_FLAG = 'RESET_ADDED_JOB_FLAG';

export const RESET_EDITED_JOB_FLAG = 'RESET_EDITED_JOB_FLAG';

export const SELECT_JOBS_FOR_JOB_SUGGESTION = 'SELECT_JOBS_FOR_JOB_SUGGESTION';

export const REMOVE_JOBS_FOR_JOB_SUGGESTION = 'REMOVE_JOBS_FOR_JOB_SUGGESTION';

export const REMOVE_ALL_JOBS_FOR_JOB_SUGGESTION = 'REMOVE_ALL_JOBS_FOR_JOB_SUGGESTION';

export const EXPORT_JOB_POSTS_REQUEST = 'EXPORT_JOB_POSTS_REQUEST';
export const EXPORT_JOB_POSTS_SUCCESS = 'EXPORT_JOB_POSTS_SUCCESS';
export const EXPORT_JOB_POSTS_FAILURE = 'EXPORT_JOB_POSTS_FAILURE';

export const DELETE_JOB_ROLE = 'DELETE_JOB_ROLE';
export const DELETE_JOB_ROLE_SUCCESS = 'DELETE_JOB_ROLE_SUCCESS';
export const DELETE_JOB_ROLE_FAILURE = 'DELETE_JOB_ROLE_FAILURE';

export const EDIT_JOB_ROLE = 'EDIT_JOB_ROLE';
export const EDIT_JOB_ROLE_SUCCESS = 'EDIT_JOB_ROLE_SUCCESS';
export const EDIT_JOB_ROLE_FAILURE = 'EDIT_JOB_ROLE_FAILURE';

export const CREATE_JOB_ROLE = 'CREATE_JOB_ROLE';
export const CREATE_JOB_ROLE_SUCCESS = 'CREATE_JOB_ROLE_SUCCESS';
export const CREATE_JOB_ROLE_FAILURE = 'CREATE_JOB_ROLE_FAILURE';


export const ONLY_JOB_ROLES = 'ONLY_JOB_ROLES';
export const ONLY_JOB_ROLES_SUCCESS = 'ONLY_JOB_ROLES_SUCCESS';
export const ONLY_JOB_ROLES_FAILURE = 'ONLY_JOB_ROLES_FAILURE';
export const SET_JOB_APPLICANTS_PAGE_NUMBER = 'JOB_APPLICANTS_PAGE_NUMBER';

export const createJob = () => ({
  type: ADD_JOB,
});

export const createJobSuccess = (response) => ({
  type: ADD_JOB_SUCCESS,
  payload: response,
});

export const createJobFailure = (error) => ({
  type: ADD_JOB_FAILURE,
  payload: error,
});

export const createJobRole = () => ({
  type: CREATE_JOB_ROLE,
});

export const createJobRoleSuccess = (response) => ({
  type: CREATE_JOB_ROLE_SUCCESS,
  payload: response,
});

export const createJobRoleFailure = (error) => ({
  type: CREATE_JOB_ROLE_FAILURE,
  payload: error,
});

export const editJob = () => ({
  type: EDIT_JOB,
});

export const editJobSuccess = (response) => ({
  type: EDIT_JOB_SUCCESS,
  payload: response,
});

export const editJobFailure = (error) => ({
  type: EDIT_JOB_FAILURE,
  payload: error,
});

export const deleteJobRole = () => ({
  type: DELETE_JOB_ROLE,
});

export const deleteJobRoleSuccess = (response) => ({
  type: DELETE_JOB_ROLE_SUCCESS,
  payload: response,
});

export const deleteJobRoleFailure = (error) => ({
  type: DELETE_JOB_ROLE_FAILURE,
  payload: error,
});

export const editJobRole = () => ({
  type: EDIT_JOB_ROLE,
});

export const editJobRoleSuccess = (response) => ({
  type: EDIT_JOB_ROLE_SUCCESS,
  payload: response,
});

export const editJobRoleFailure = (error) => ({
  type: EDIT_JOB_ROLE_FAILURE,
  payload: error,
});

export const deleteJob = () => ({
  type: DELETE_JOB,
});

export const deleteJobSuccess = (response) => ({
  type: DELETE_JOB_SUCCESS,
  payload: response,
});

export const deleteJobFailure = (error) => ({
  type: DELETE_JOB_FAILURE,
  payload: error,
});

export const bulkUpdateJob = () => ({
  type: BULK_UPDATE_JOB,
});

export const bulkUpdateJobSuccess = (response) => ({
  type: BULK_UPDATE_JOB_SUCCESS,
  payload: response,
});

export const bulkUpdateJobFailure = (error) => ({
  type: BULK_UPDATE_JOB_FAILURE,
  payload: error,
});

export const resetJobAdded = () => ({
  type: RESET_ADDED_JOB_FLAG,
})

export const resetJobEdited = () => ({
  type: RESET_EDITED_JOB_FLAG,
})

export const onlyJobRoles = () => ({
  type: ONLY_JOB_ROLES,
});

export const onlyJobRolesSuccess = (response) => ({
  type: ONLY_JOB_ROLES_SUCCESS,
  payload: response,
});

export const onlyJobRolesFailure = (error) => ({
  type: ONLY_JOB_ROLES_FAILURE,
  payload: error,
});

export const getJobRoles = () => ({
  type: JOB_ROLES,
});

export const getJobRolesSuccess = (response) => ({
  type: JOB_ROLES_SUCCESS,
  payload: response,
});

export const getJobRolesFailure = (error) => ({
  type: JOB_ROLES_FAILURE,
  payload: error,
});

export const getJobs = () => ({
  type: GET_JOBS,
});

export const getJobsSuccess = (response) => ({
  type: GET_JOBS_SUCCESS,
  payload: response,
});

export const getJobsFailure = (error) => ({
  type: GET_JOBS_FAILURE,
  payload: error,
});

export const setJobsFilters = (response) => ({
  type: SET_JOBS_FILTERS,
  payload: response,
});

export const getJobsAudit = () => ({
  type: GET_JOBS_AUDIT,
});

export const getJobsAuditSuccess = (response) => ({
  type: GET_JOBS_AUDIT_SUCCESS,
  payload: response,
});

export const getJobsAuditFailure = (error) => ({
  type: GET_JOBS_AUDIT_FAILURE,
  payload: error,
});

export const getSpecificJobsAudit = () => ({
  type: GET_SPECIFIC_JOBS_AUDIT,
});

export const getSpecificJobsAuditSuccess = (response) => ({
  type: GET_SPECIFIC_JOBS_AUDIT_SUCCESS,
  payload: response,
});

export const getSpecificJobsAuditFailure = (error) => ({
  type: GET_SPECIFIC_JOBS_AUDIT_FAILURE,
  payload: error,
});

export const resetSpecificJobsAudit = () => ({
  type: RESET_SPECIFIC_JOBS_AUDIT,
});

export const getJobById = () => ({
  type: GET_SINGLE_JOB_BY_ID,
});

export const getJobByIdSuccess = (response) => ({
  type: GET_SINGLE_JOB_BY_ID_SUCCESS,
  payload: response,
});

export const getJobByIdFailure = (error) => ({
  type: GET_SINGLE_JOB_BY_ID_FAILURE,
  payload: error,
});

export const getJobApplicants = () => ({
  type: GET_JOB_APPLICANTS,
});

export const getJobApplicantsSuccess = (response) => ({
  type: GET_JOB_APPLICANTS_SUCCESS,
  payload: response,
});

export const getJobApplicantsFailure = (error) => ({
  type: GET_JOB_APPLICANTS_FAILURE,
  payload: error,
});

export const getSuggestedCandidates = () => ({
  type: GET_SUGGESTED_CANDIDATES,
});

export const getSuggestedCandidatesSuccess = (response) => ({
  type: GET_SUGGESTED_CANDIDATES_SUCCESS,
  payload: response,
});

export const getSuggestedCandidatesFailure = (error) => ({
  type: GET_SUGGESTED_CANDIDATES_FAILURE,
  payload: error,
});

export const selectJobsForJobSuggestion = (jobs) => ({
  type: SELECT_JOBS_FOR_JOB_SUGGESTION,
  payload: jobs
});

export const removeJobsForJobSuggestion = (jobId) => ({
  type: REMOVE_JOBS_FOR_JOB_SUGGESTION,
  payload: jobId
});

export const removeAllJobsForJobSuggestion = () => ({
  type: REMOVE_ALL_JOBS_FOR_JOB_SUGGESTION
});

const exportJobPostsRequest = () => ({
  type: EXPORT_JOB_POSTS_REQUEST,
});

const exportJobPostsSuccess = () => ({
  type: EXPORT_JOB_POSTS_SUCCESS,
});

const exportJobPostsFailure = (error) => ({
  type: EXPORT_JOB_POSTS_FAILURE,
  error,
});

export const setJobApplicantsPageNumber = (pageNumber) => ({
  type: SET_JOB_APPLICANTS_PAGE_NUMBER,
  payload: pageNumber
});

export const getJobsList = (payload) => async (dispatch) => {
  dispatch(getJobs());
  dispatch(startLoader());

  try {
    const response = await getJobsService(payload);

    if (response.success) {
      dispatch(getJobsSuccess(response));
    } else {
      dispatch(getJobsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getJobsFailure('Error getting job list'));
    showToast('Error getting job list', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getJobsAuditList = (payload) => async (dispatch) => {
  dispatch(getJobsAudit());
  dispatch(startLoader());

  try {
    const response = await getJobsAuditService(payload);

    if (response.success) {
      dispatch(getJobsAuditSuccess(response));
    } else {
      dispatch(getJobsAuditFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getJobsAuditFailure('Error getting job audit list'));
    showToast('Error getting job activity logs', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getSpecificJobsAuditList = (jobPostId) => async (dispatch) => {
  dispatch(getSpecificJobsAudit());
  dispatch(startLoader());

  try {
    const response = await getSpecificJobsAuditService(jobPostId);

    if (response.success) {
      dispatch(getSpecificJobsAuditSuccess(response));
    } else {
      dispatch(getSpecificJobsAuditFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getSpecificJobsAuditFailure('Error getting specific job audit list'));
    showToast('Error getting job activity logs', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getJobApplicantsList = (jobPostId, pageNumber = 0) => async (dispatch) => {
  dispatch(getJobApplicants());
  dispatch(startLoader());

  try {
    const response = await getJobApplicantsService(jobPostId, pageNumber);

    if (response.success && response.data.length) {
      dispatch(getJobApplicantsSuccess(response));
    }
    if (!response.data.length) {
      showToast('No Job Applicants List', 'error')
    }
    else {
      dispatch(getJobApplicantsFailure(response.message));
    }
  } catch (error) {
    dispatch(getJobApplicantsFailure('Error getting job applicants'));
    showToast('Error getting job applicants', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getSuggestedCandidatesList = (jobPostId) => async (dispatch) => {
  dispatch(getSuggestedCandidates());
  dispatch(startLoader());

  try {
    const response = await getSuggestedCandidatesService(jobPostId);

    if (response.success && response.data.length) {
      dispatch(getSuggestedCandidatesSuccess(response));
    }
    if (!response.data.length) {
      showToast('No Suggested Candidates', 'error')
    } else {
      dispatch(getSuggestedCandidatesFailure(response.message));
    }
  } catch (error) {
    dispatch(getSuggestedCandidatesFailure('Error getting suggested candidates'));
    showToast('Error getting suggested candidates', 'error');
  } finally {
    dispatch(stopLoader());
  }
};


export const getJobByIdDetails = (jobPostId) => async (dispatch) => {
  dispatch(getJobById());
  dispatch(startLoader());

  try {
    const response = await getJobPostByIdService(jobPostId);

    if (response.success) {
      dispatch(getJobByIdSuccess(response));
    } else {
      dispatch(getJobByIdFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getJobByIdSuccess('Error getting job details'));
    showToast('Error getting job details', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const createNewJob = (payload) => async (dispatch) => {
  dispatch(createJob());
  dispatch(startLoader());

  try {
    const response = await createJobService(payload);

    if (response.success) {
      // await getJobsList();
      dispatch(createJobSuccess(true));
      showToast(response.message, 'success')

    } else {
      dispatch(createJobFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(createJobFailure('Error adding job'));
    showToast('Error adding job', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const editJobRecord = (jobPostId, payload) => async (dispatch, getState) => {
  dispatch(editJob());
  dispatch(startLoader());

  try {
    const response = await editJobService(jobPostId, payload);

    if (response.success) {
      dispatch(editJobSuccess(response.data));
      showToast(response.message, 'success')
    } else {
      dispatch(editJobFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(editJobFailure('Error editing job'));
    showToast('Error editing job', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const getJobRolesList = (payload) => async (dispatch) => {
  dispatch(getJobRoles());
  dispatch(startLoader());

  try {
    const response = await jobListsService(payload);
    if (response.success) {

      dispatch(getJobRolesSuccess(response));
    } else {
      dispatch(getJobRolesFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(getJobRolesFailure('Error getting job roles'));
    showToast('Error getting job roles', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const deleteJobRecord = (jobPostId) => async (dispatch, getState) => {
  dispatch(deleteJob());
  dispatch(startLoader());

  try {
    const response = await deleteJobService(jobPostId);

    if (response.success) {
      dispatch(deleteJobSuccess(jobPostId));
      showToast(response.message, 'success')
    } else {
      dispatch(deleteJobFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(deleteJobFailure('Error deleting job'));
    showToast('Error deleting job', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const clearAddedJobFlag = () => async (dispatch) => {
  dispatch(resetJobAdded(null));
}

export const clearEditedJobFlag = () => async (dispatch) => {
  dispatch(resetJobEdited(null));
}

export const setSelectJobsForJobSuggestion = (payload) => async (dispatch) => {
  dispatch(selectJobsForJobSuggestion(payload));
}

export const removeSelectJobsForJobSuggestion = (jobId) => async (dispatch) => {
  dispatch(removeJobsForJobSuggestion(jobId));
}

export const removeAllJobsFromJobSuggestion = () => async (dispatch) => {
  dispatch(removeAllJobsForJobSuggestion());
}

export const exportJobPostListInExcel = (payload) => async (dispatch) => {
  dispatch(exportJobPostsRequest());
  dispatch(startLoader());
  try {
    const response = await exportJobPostListService(payload);

    if (response.success) {
      dispatch(exportJobPostsSuccess());
      showToast(response.message, 'success');
    } else {
      dispatch(exportJobPostsFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(exportJobPostsFailure('Error exporting job post data'));
    showToast('Error exporting job post', 'error');
  } finally {
    dispatch(stopLoader());
  }
}

export const deleteJobRoleById = (jobRoleId) => async (dispatch, getState) => {
  dispatch(deleteJobRole());
  dispatch(startLoader());

  try {
    const response = await deleteJobRoleService(jobRoleId);
    if (response.success) {
      dispatch(deleteJobRoleSuccess(jobRoleId));
      showToast(response.message, 'success')

    } else {
      dispatch(deleteJobRoleFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(deleteJobRoleFailure('Error deleting job'));
    showToast('Error deleting job', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const editJobRoleById = (jobPostId, payload) => async (dispatch, getState) => {
  dispatch(editJobRole());
  dispatch(startLoader());

  try {
    const response = await patchJobRoleService(jobPostId, payload);

    if (response.success) {
      dispatch(editJobRoleSuccess(response.data));
      showToast(response.message, 'success')
    } else {
      dispatch(editJobRoleFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(editJobRoleFailure('Error editing job role'));
    showToast('Error editing job role', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const addJobRole = (payload) => async (dispatch, getState) => {
  dispatch(createJobRole());
  dispatch(startLoader());

  try {
    const response = await createJobRoleService(payload);
    if (response.success) {
      dispatch(createJobRoleSuccess(true));
      showToast(response.message, 'success')

      setTimeout(() => {
        window.location.reload();
      }, 1000)
    } else {
      dispatch(createJobFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(createJobFailure('Error creating job role'));
    showToast('error creating job role', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const onlyJobRolesList = () => async (dispatch) => {
  dispatch(onlyJobRoles());
  dispatch(startLoader());

  try {
    const response = await onlyJobRolesService();
    if (response.success) {
      const uniqueJobRoles = [...new Set(response.data)];
      let jobRoles = [];

      uniqueJobRoles?.forEach(element => {
        jobRoles.push({
          id: element,
          name: element
        })
      });
      dispatch(onlyJobRolesSuccess(jobRoles));
    }
  } catch (error) {
    dispatch(onlyJobRolesFailure('Error getting job roles'));
    showToast('Error getting job roles', 'error');
  } finally {
    dispatch(stopLoader());
  }
};

export const bulkUpdateJobPosts = (payload) => async (dispatch) => {
  dispatch(bulkUpdateJob());
  dispatch(startLoader());

  try {
    const response = await bulkUpdateJobService(payload);

    if (response.success) {
      dispatch(bulkUpdateJobSuccess(true));
      showToast(response.message, 'success')

    } else {
      dispatch(bulkUpdateJobFailure(response.message));
      showToast(response.message, 'error');
    }
  } catch (error) {
    dispatch(bulkUpdateJobFailure('Error updating job'));
    showToast('Error updating job', 'error');
  } finally {
    dispatch(stopLoader());
  }
};
